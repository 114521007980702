.section-intro-home {
    text-align: center;
    margin: -140px auto 0 auto;
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.04);

    &-background-image {
        background: url(/blog-img/home/Video.png) no-repeat center center;
        height: 102vh;
        background-size: cover;
        color: $background-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-position: 50% 80%;

        @include media-breakpoint-down(sm) {
            background-position: 100% 50%;
        }

    }
    &-container {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-70%);
        @media (max-height: 890px) {
            transform: translateY(-50%);
        }
    }

    &-subtitle {
        text-align: center;
        font-size: $font-size-list-title;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 6px;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }
    }

    &-title {
        margin: 20px auto 40px auto;
        @include media-breakpoint-down(sm) {
            font-size: 41px;
            line-height: 50px;
            font-weight: 300;
            padding: 0 $spacer-xsmall;
        }
    }

    &-content {
        width: 500px;
        margin: 30px auto;
    }


    &-scroll {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

    }
}

.slick-slide {
    width: 350px;
}

.read-more {
    margin-top: $spacer;
}

.read-more-link {
    color: $background-color;
    font-size: $font-size-button;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 3px;
    cursor: pointer;
    &:hover {
        color: $font-color-blue;
    }
}