.section-intro-academy {
    &-background-image {
        background: url(/blog-img/academy/Visual.png) no-repeat center center;
        height: 100vh;
        background-size: cover;
    }
    &-container {
        position: absolute;
        width: 100%;
        top: 40%;
        transform: translateY(-70%);
        @include media-breakpoint-down(sm) {
            padding-top: 500px;
        }
    }
    &-title {
        text-align: center;
        padding-top: 185px;
        margin: 0;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-title;
            font-weight: 300;
            padding-top: 50px;
        }
    }
    &-content {
        text-align: center;
        max-width: 800px;
        margin: 30px auto;
        padding: 0 $spacer-small;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-card-content;
        }
    }
    &-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include media-breakpoint-down(sm) {
            padding: 0 $spacer-xsmall;
        }
    }
    &-button {
        margin: 0 $spacer-small;
        @include media-breakpoint-down(sm) {
            margin: 0 $spacer-xsmall;
        }
    }
    .button-article {
        background-color: $background-color-violet;
    }
    .button-course {
        background-color: $background-color-green;
    }
}
