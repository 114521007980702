.section-offer-pricing {
    border: 1px solid $border-light-gray;
    text-align: center;
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.04);

    &-content {
        margin: 95px;

        @include media-breakpoint-down(md) {
            margin: 0px 5px $spacer 5px;
        }
    }

    h3 {
        font-size: $font-size-heading;
        font-weight: 100;
        color: $font-color-black;
        margin: $spacer-content;

        @include media-breakpoint-down(md) {
            font-size: $font-size-title;
            margin: 50px 0 25px 0;
            font-weight: 300;
        }
    }

    &-link {
        font-weight: 500;
        letter-spacing: 1px;
        color: $font-color-black;
        font-size: $font-size-button;
        border-bottom: 1px solid $border-color;
    }

    &-table {
        width: $container-width;
        margin: 0 auto $spacer auto;
        border-left: 1px solid transparent;

        @include media-breakpoint-down(md) {
            display: block;
            width: 90vw;
        }

        &.marked {
            mask-image: linear-gradient(to top, transparent 7%, white 50%);
            -webkit-mask-image: linear-gradient(
                to top,
                transparent 7%,
                white 50%
            );
        }
    }

    thead,
    &-title-container {
        @include media-breakpoint-down(md) {
            display: flex;
            width: 100%;
        }
    }

    &-title {
        background-color: #f5f5f5;
        color: $font-color-black;
        font-size: $font-size-footer-header;
        line-height: 30px;
        text-align: center;
        font-weight: 400;
        padding: 30px 100px;
        border-top: 1px solid $border-light-gray;

        @include media-breakpoint-down(md) {
            padding: 30px 0;
            flex-grow: 1;
            display: block;
        }
    }
    .individual-title {
        border-radius: 6px 0 0 0;
    }
    .team-title {
        border-right: 2px solid $border-color-gray;
        border-left: 2px solid $border-color-gray;
    }
    .enterprise-title {
        border-radius: 0 6px 0 0;
    }

    &-title-left {
        width: 40%;
        background: none;
        border-left: 1px solid transparent;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &-section-title {
        font-weight: 400;
        font-size: $font-size-footer-header;
        border-top: 1px solid $border-light-gray;
        padding-left: $spacer;
        color: $font-color-blue;

        &.main {
            @include media-breakpoint-down(md) {
                padding-top: $spacer;
            }
        }

        @include media-breakpoint-down(md) {
            display: block;
            width: 90vw;
        }

        &.hide {
            display: none;
        }
    }

    &-name {
        font-weight: 400;
        font-size: $font-size-content;
        color: $font-color-gray;
        padding-left: $spacer;

        @include media-breakpoint-down(md) {
            // !important to overwrite js show/hide script
            display: inline-block !important;
            width: 50%;
        }

        &.hide {
            display: none;
        }
    }

    &-check {
        text-align: center;
        position: relative;

        @include media-breakpoint-down(md) {
            // !important to overwrite js show/hide script
            display: inline-block !important;
            float: right; // to work properly on Firefox
            width: 50%;
            height: 41px;
            border-left: none;
            border-right: 1px solid #f2f2f2;
        }

        &:not(.active-cell) {
            @include media-breakpoint-down(md) {
                // !important to overwrite js show/hide script
                display: none !important;
            }
        }

        &.hide {
            display: none;
        }
    }
}

.section-offer-pricing-check {
    text-align: center;
}

.pricing-underline {
    height: 3px;
    width: 100px;
    background-color: $font-color-blue;
    margin: 0 auto 70px auto;

    @include media-breakpoint-down(md) {
        margin: 0 auto 50px auto;
    }
}

.offer-tab {
    cursor: pointer;
}

.offer-tab-active {
    background-color: $background-color;
}
