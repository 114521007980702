.site-footer {
    padding-top: $spacer-content;
    padding-bottom: $spacer-content;
    font-size: $font-size-content;
    font-weight: 400;
    background-color: $background-color-black;
    position: relative;

    &_signet {
        position: absolute;
        top: 0;
        right: $spacer;
        @include media-breakpoint-down(xs) {
            right: 0;

        }
    }

    &__footer-link {
        padding-top: 0;
        color: $font-color-footer;

        &:hover, &.terms:hover {
            color: $font-color-blue;
        }

        &-active {
            color: $font-color-blue;
        }

        &.terms {
            color: $font-color-gray;
            padding: 0 10px;
            font-weight: 400;
        }
    }

    &__container {
        max-width: $container-width;
        margin: 70px auto;
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            margin: 70px $spacer-small;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            margin: 70px $spacer-small 50px $spacer-small;
        }
    }

    &__row {
        flex-basis: 33%;
        padding: 0 $spacer;
        z-index: 100;

        @include media-breakpoint-down(md) {
            padding: 0 $spacer-xsmall 0 $spacer-small;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 $spacer-xsmall 0 0;
        }

    }

    &__section-header {
        margin-top: 20px;
        margin-bottom: 19px;
        font-size: $font-size-footer-header;
        letter-spacing: 1px;
        font-weight: 400;
        color: $background-color;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        &-social {
            display: flex;
            justify-content: flex-start;

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }

        &-footer-followus {
            margin-right: $spacer-small;

            @include media-breakpoint-down(sm) {
                margin: $spacer-xsmall;
            }

        }
    }

    &__credits {
        font-size: $font-size-button;
        padding: 0 $spacer-small 140px $spacer-small;
        display: flex;
        max-width: $container-width;
        margin: $spacer auto;
        color: $font-color-gray;
        font-weight: 400;

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
            padding: 0 $spacer-small 50px $spacer-small;
        }

        &-copy {
            flex-basis: 50%;

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                text-align: center;
            }
        }

        &-terms {
            flex-basis: 50%;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                justify-content: center;
                flex-direction: row;
                display: inline;
                text-align: center;
                margin-bottom: $spacer-small;
            }
        }
    }

    &__button-support {
        font-size: $font-size-button;
        font-weight: 500;
        text-transform: uppercase;
        border: 1px solid $background-color;
        border-radius: 4px;
        padding: $spacer-small 25px;
        margin-top: 30px;
        display: inline-block;
        color: $background-color;
        width: 150px;

        @include media-breakpoint-down(sm) {
            margin: 30px auto 30px auto;
            text-align: center;
            display: flex;
            justify-content: center;
        }

        &:hover {
            border-color: $font-color-blue;
            ;
        }
    }

    &__horizontal-bar {
        height: 1px;
        max-width: $container-width;
        background-color: #404040;
        margin: 90px auto 0 auto;

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }

    }

    &__textwidget {
        &-quicklinks {
            display: flex;
            color: white;
            line-height: 35px;

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }

            .left-column,
            .right-column {
                flex-basis: 50%;

                @include media-breakpoint-down(sm) {
                    justify-content: center;
                    padding: 0 15px;
                    text-align: left;
                    flex-basis: initial;
                }
            }
            .right-column {
                padding-left: $spacer;
            }
        }

        &-support {
            color: $font-color-footer;

            @include media-breakpoint-down(sm) {
                padding: 0 15px;
                text-align: center;
            }
        }
    }
}

.cookies {
    position: fixed;
    z-index: 1000;
    max-width: 1140px;
    border: 1px solid $border-color;
    border-radius: 5px;
    background-color: $background-color;
    box-shadow: 0 22px 24px 0 rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        bottom: 10%;
        padding: $spacer-small $spacer-content*3;
    }
    &--hidden {
        display: none;
    }
    padding: $spacer-xsmall;
    bottom: 0;
    left: 0;
    right: 0;
    &__icon {
        flex: 0 0 auto;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    &__text {
        max-width: 632px;
        font-size: $font-size-base;
        line-height: 23px;
        margin: $spacer-xsmall $spacer-small;
    }
    &__button {
        flex: 0 0 auto;
        width: 77px;
        height: 40px;
        padding: 0;
        display: block;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
            margin: $spacer-xsmall auto 0;
        }
    }
    &__link {
        font-weight: 300;
        color: #666;
        text-decoration: underline;
        white-space: nowrap;
    }
}