.modal {
    z-index: 999;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    &-window {
        top: -100%;
        width: 80vw;
        max-width: 1000px;
        height: 620px;
        background-color: $background-color;
        margin: 100px auto;
        border-radius: 5px;
        position: relative;
        iframe {
            width: 80vw;
            max-width: 900px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-close {
        font-size: $font-size-card-content;
        border: none;
        background: transparent;
        font-weight: bold;
        float: right;
        margin: $spacer-small;
        cursor: pointer;
    }
}
.modal.isOpen {
    display: block;
    & .modal-window {
        animation: entranceTop 0.5s;
        top: 0;
    }
}

@keyframes entranceTop {
    0% {
        top: -100%;
    }
    100% {
        top: 0;
    }
}
