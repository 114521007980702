.doc-article {
    &-container {
        margin: 150px auto 0;
        padding: 0 40px;
    }


    &-menu {
        display: flex;
        max-width: 1400px;
        margin: 200px auto $spacer;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    &-nav {
        display: flex;
        flex-direction: column;
        list-style: none;
        flex: 0 0 25%;
        margin-right: $spacer+$spacer-xsmall;
        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
        margin-top: 0;
        padding: 0;

        @include media-breakpoint-down(sm) {
            flex-basis: 30%;
            margin: 0 $spacer-small $spacer-small $spacer-small;
            padding: 0
        }
    }

    &-content {
        flex: 0 0 75%;
        /* HACK BO SIĘ ŹLE ZACHOWUJE */
        max-width: 900px;
        h3 {
            margin-top: 0;
        }
        @include media-breakpoint-down(sm) {
            flex-basis: 70%;
        }
    }
}