$font-color-blue: #006DFF;
$font-color-green: #38C591;
$font-color-violet: #5553CE;
$font-color-gray: #666666;
$font-color-footer: #999999;

$background-color-violet:#5553CE;
$background-color-green:#40C38F;
$background-color-light-gray: #F8F8F8;
$background-color-black: #292A2B;
$background-color: #FFFFFF;

$border-light-gray: #F2F2F2;
$border-color: #DCDCDC;
$border-color-gray:#EDEDED;

$font-color-black: #000000;

$slider-gray: #E9E9E9;

$font-size-base: 14px;
$font-size-main-title: 75px;
$font-size-heading: 55px;
$font-size-home-menu: 50px;
$font-size-title: 35px;
$font-size-subtitle: 10px;
$font-size-teaser-title: 25px;
$font-size-teaser-name: 12px;
$font-size-content: 15px;
$font-size-button: 13px;
$font-size-footer-header: 20px;
$font-size-list-title: 18px;
$font-size-card-title: 22px;
$font-size-card-content: 16px;

$header-height: 122px;

$spacer: 35px;
$spacer-small: 15px;
$spacer-xsmall: 5px;
$spacer-content: 30px;

$container-width: 1200px;

$grid-breakpoints: (
        xs: 0,
        sm: 481px,
        md: 769px,
        lg: 992px,
        xl: 1200px
);
