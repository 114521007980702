.section-intro-features {
    text-align: center;
    margin: 0 auto;
    padding: 120px 0 170px;
    border: 1px solid $border-light-gray;
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.04);
    @include media-breakpoint-down(lg) {
        padding: 70px 0;
    }
    @include media-breakpoint-down(sm) {
        border: none;
    }
    &.sticky-section {
        border-bottom: none;
    }
    &-favikon {
        margin: 0 auto $spacer-small auto;
    }

    h2 {
        position: relative;
        margin: 25px 0;
        @include media-breakpoint-down(sm) {
            font-weight: 300;
            font-size: $font-size-title;
        }

        &::after {
            content: "";
            width: 100px;
            height: 3px;
            background-color: $font-color-blue;
            display: block;
            position: absolute;
            margin: 0 auto;
            border-top: 2px solid transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;

            @include media-breakpoint-down(sm) {
                bottom: -20px;
            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;
        max-width: $container-width;
        margin: 75px auto 0 auto;

        @include media-breakpoint-down(md) {
            margin: 70px auto 0 auto;
            flex-direction: column;
        }
    }

    &-content-visual {
        display: flex;
        align-items: flex-start;
        flex-basis: 50%;
        max-width: 70%;
        margin-right: -20%;
        & > img {
            @include media-breakpoint-up(xl) {
                max-width: 770px;
            }
        }

        @include media-breakpoint-down(md) {
            margin-top: $spacer;
            max-width: 100%;
        }
    }

    &-content-list {
        flex-basis: 50%;
        text-align: left;
        padding-left: $spacer-small;
        p {
            margin: 25px 0;
        }

        ul {
            list-style-type: none;
            margin-top: 0;
            @include media-breakpoint-down(sm) {
                padding-left: $spacer-small;
            }

            li::before {
                content: ".";
                margin-right: 1rem;
                position: relative;
                top: 30px;
                font-weight: 600;
                color: $font-color-gray;
                left: -15px;
            }
        }

        &.internal-list {
            padding: 0 $spacer;

            li::before {
                top: -5px;
            }
        }

        @include media-breakpoint-down(md) {
            padding-right: 10px;
        }
    }

    .content-list-button {
        padding: $spacer-small 25px;
        margin-top: $spacer;
        margin-left: 40px;

        @include media-breakpoint-down(sm) {
            margin: $spacer auto 0 auto;
        }
        &.internal-list {
            margin-left: 0;
        }
        &.home-button {
            margin-left: 120px;

            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }

        &.academy {
            margin-left: 0;
            margin-top: $spacer;

            @include media-breakpoint-down(xs) {
                margin: $spacer-small auto;
                display: flex;
                justify-content: center;
                max-width: 116px;
            }
        }
        &-with-read-more {
            display: flex;
            justify-content: flex-start;
            margin: 60px 0 0 50px;
            &.center {
                justify-content: center;
            }

            @include media-breakpoint-down(md) {
                justify-content: center;
                margin: 70px 0 30px 0;
            }

            .content-list-button {
                margin: 0;
            }

            &.home-button {
                margin: 50px 0 0 105px;
                @include media-breakpoint-down(md) {
                    margin: 50px 0;
                }
            }
        }
    }

    .read-more {
        margin-left: $spacer;
        display: flex;
        margin-top: 0;

        @include media-breakpoint-down(md) {
            margin-left: $spacer-small;
        }
    }

    .read-more-link {
        font-size: $font-size-button;
        color: $font-color-black;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        align-self: center;
        &:hover {
            color: $font-color-blue;
        }
    }

    &-content-title {
        font-size: $font-size-list-title;
        line-height: 28px;
        font-weight: 400;
        text-align: left;
        color: $font-color-black;
        padding: $spacer-xsmall 0 $spacer-small;
    }

    &-content-meat {
        @include media-breakpoint-down(md) {
            font-size: $font-size-card-content;
            line-height: 25px;
        }
    }

    &-content-circles {
        ul {
            display: flex;
            list-style: none;
            flex-wrap: wrap;
            padding-left: 0;
            justify-content: center;

            li {
                box-sizing: border-box;
                height: 110px;
                width: 110px;
                border: 1px solid #ebebeb;
                background-color: #ffffff;
                border-radius: 50%;
                margin: $spacer $spacer-small 90px;
                position: relative;
            }
        }
    }

    &-content-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        img {
            max-width: 75px;
            max-height: 75px;
        }
    }

    &-content-circle-info {
        font-size: $font-size-base;
        color: $font-color-gray;
        position: absolute;
        top: 170px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -160%);
    }
}
#supported_databases {
    padding-top: 200px;
    @include media-breakpoint-down(lg) {
        padding: 70px 0;
    }
}
#managing_models {
    box-shadow: none;
    border-bottom: none;
}
.top-space {
    margin-top: $spacer;
}
.features-slick-carousel {
    width: 100%;
}
