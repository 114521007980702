.blog-list {
    &-container {
        max-width: 1200px;
        margin: 150px auto 50px auto;
    }
    &-menu {
        display: flex;
        margin-top: $spacer;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    &-nav {
        display: flex;
        flex-direction: column;
        list-style: none;
        flex-basis: 25%;
        margin-right: $spacer+$spacer-xsmall;
        margin-top: $spacer;

        @include media-breakpoint-down(sm) {
            flex-basis: 30%;
            margin: 0 $spacer-small $spacer-small $spacer-small;
            padding: 0
        }
    }

    &-nav-item {
        border-bottom: 1px solid $border-color-gray;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        &:last-child {
            border-bottom: none;
        }
        &.marked {
            color: $font-color-blue;
            }
    }

    &-nav-link {
        color: $font-color-gray;
        font-size: $font-size-list-title;
        font-weight: 400;
        display: block;
        line-height: 55px;
        &.marked {
            color: $font-color-blue;
            }
    }

    &-content {
        flex-basis: 75%;

        @include media-breakpoint-down(sm) {
            flex-basis: 70%;
        }
    }

    &-summary {
        display: flex;
        border-bottom: 1px solid $border-color-gray;
        padding: 50px 0;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            padding-top: $spacer;
        }
    }

    &-summary-info {
        flex-basis: 70%;
        padding: 0 $spacer;

        @include media-breakpoint-down(lg) {
            padding: $spacer-small;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 $spacer-small $spacer-small $spacer-small;
            padding: 0;
        }

        .summary__content {
            margin-top: $spacer-small+$spacer-xsmall;
            text-decoration: none;
            color: $font-color-gray;
            display: inline-block;
        }
    }
    &-summary-title {
        margin-top: 0;
        margin-bottom: $spacer-xsmall;
        line-height: 40px;
        &:hover a {
            color: $font-color-blue;
        }

        a {
            font-size: $font-size-title;
            font-weight: 300;
            color: $font-color-black;
        }
    }

    &-feature-image {
        flex-basis: 30%;
        background-size: cover;
        background-position: 50% 20%;
        height: 260px;
        padding: 0;
        border-radius: 5px;
        -webkit-filter: brightness(0.8);
        -moz-filter: brightness(0.8);
        -o-filter: brightness(0.8);
        -ms-filter: brightness(0.8);
        filter: brightness(0.8);
        &.narrow {
            margin-bottom: $spacer;
        }
        &-link {
            flex-basis: 30%;
        }
        @include media-breakpoint-down(lg) {
            margin: $spacer-small;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 $spacer-small $spacer-small $spacer-small;
        }

        @include media-breakpoint-down(xs) {
            padding: 100px 0;
        }

        img {
            display: none;
        }
    }
}

.summary-blog-post-meta {
    &-author {
        display: inline;
    }

    &-author-img {
        flex: 0 0 32px;
    }

    &-list-author-name {
        color: $font-color-black;
        font-size: $font-size-button;
        line-height: 15px;
        font-weight: 400;
    }

    &.teaser {
        display: inline;
    }
}

.summary-read-more {
    &.blog-list {
        margin-top: $spacer;
    }

    &-link {
        font-size: $font-size-button;
        text-transform: uppercase;
        color: $font-color-black;
        font-weight: 500;
        letter-spacing: 1px;
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        padding-bottom: 2px;
    }
}

.blog-post-category {
    display: block;
    color: $font-color-blue;
    font-size: $font-size-teaser-name;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0 0 $spacer-small 0;
}

.blog-post-date {
    font-size: $font-size-button;
    color: $font-color-black;
    font-weight: 400;
    padding-left: $spacer;
}