/*
    TABS
    Pricelist Card
    Zipper ranged slider
*/

// TABS
$menuItems: 2;
$width: (100 / $menuItems) * 1%;

.section-intro-pricing {
    border: 1px solid $border-light-gray;
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.04);

}

.tabs-container {
    overflow-x: auto;
    overflow-y: hidden;
    list-style-type: none;
    display: flex;
    max-width: 720px;
    margin: 92px auto;
    justify-content: space-evenly;
    padding: 10px 0;
    position: relative;
    font-family: "Roboto", sans-serif;

    &>* {
        transition: 0.5s cubic-bezier(.69, .26, .21, .78);
    }

    @include media-breakpoint-down(sm) {
        margin: 0 $spacer-small;
    }
}

.tab {
    padding: 10px;
    text-align: center;
    width: $width;
    line-height: 50px;
    font-size: $font-size-footer-header;

    a {
        color: $font-color-gray;
        text-decoration: none;
        font-weight: normal;

        img {
            vertical-align: middle;
        }

        &:hover {
            color: $font-color-blue;
        }
    }
}

.underline {
    background-color: $font-color-blue;
    height: 3px;
    border-radius: 25px;
    width: $width;
    position: absolute;
    bottom: 0;
    left: 0;
}

.gray-line {
    background-color: #ececec;
    height: 1px;
    border-radius: 25px;
    width: $width * $menuItems;
    position: absolute;
    bottom: 0;
}

.tab-active a {
    color: $font-color-blue;
    font-weight: 500;
}

// Pricelist Card
.pricelist-card {
    display: flex;
    justify-content: center;
    max-width: 1140px;
    margin: $spacer-content auto;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);

    @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
        max-width: none;
        border: none;
        box-shadow: none;
        flex-direction: column;
        margin: $spacer-content auto;
        width: 90vw;
    }
}

#month {
    display: none;
}

.card-intro {
    display: block;
    text-align: center;
    border: none;
    flex-basis: 33%;
    padding-bottom: $spacer;

    @include media-breakpoint-down(md) {
        flex-basis: 100%;
        border: 1px solid #f5f5f5;
        border-radius: 6px;
        margin-bottom: $spacer;
    }
}

.card-intro:not(:first-child) .card-header {
    @include media-breakpoint-down(md) {
        height: 140px;
    }
}

.card-individual,
.card-enterprise {
    background-color: $background-color;
}

.card-team {
    background-color: $background-color-light-gray;
}

.card-header {
    background-color: transparent;
    border-bottom: 1px solid $border-color-gray;
    height: 179px;

    h3 {
        font-size: 22px;
        font-weight: normal;
        color: $font-color-black;
        margin-top: 40px;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 300;
        color: $font-color-gray;
    }
}

.card-body {
    .price {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 105px;
        font-weight: 100;
        margin: $spacer-xsmall;

        &::before {
            content: "$ ";
            font-size: $font-size-heading;
        }
    }
    .custom {
        display: flex;
        align-items: center;
        height: 178px;
        margin: $spacer-xsmall;
        &__mail {
            color: $font-color-blue;
            font-weight: 500;
        }
    }

    .monthly {
        font-size: $font-size-list-title;
        font-weight: 300;
        line-height: 24px;
    }

    .card-description {
        list-style-type: none;
        font-size: $font-size-base;
        color: $font-color-gray;
        margin: $spacer-xsmall auto $spacer auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 250px;

        &-strong {
            font-weight: 500;
            color: $font-color-black;
        }

        li {
            position: relative;
            text-align: left;
            line-height: 30px;
            display: inline !important;

            strong {
                color: $font-color-black;
            }

            &::before {
                content: "";
                background: url(../blog-img/pricing/check.png);
                position: absolute;
                height: 11px;
                width: 12px;
                left: -25px;
                top: 5px
            }
        }
    }

    .savings {
        font-size: $font-size-base;
        color: $font-color-violet;
        font-weight: normal;
    }
}

.payment {
    width: 532px;
    margin: 100px auto;
    text-align: center;

    @include media-breakpoint-down(md) {
        margin: 0 auto 50px auto;
    }

    @include media-breakpoint-down(sm) {
        width: 90vw;
    }

    &-text p {
        font-size: 12px;
        color: $font-color-gray;
        font-weight: 300;
    }

    &-logos img {
        margin: 0 $spacer-xsmall;
        vertical-align: middle;
    }
}
.sign-up-button {
    display: block;
    margin: 0 auto $spacer-small;
    max-width: 148px;
}

// Zipper range slider
$sliderWidth: 100%;
$sliderActualWidth: 180px;
// if you're going to change number of slider's steps, change this variable
$sliderSteps: 2;

// reseting default styles
input[type="range"] {
    -webkit-appearance: none;
    width: $sliderActualWidth;
    /* Specific width is required for Firefox. */
    display: block;
    margin: $spacer auto;
    background: transparent;
    position: relative;

    &::-ms-track {
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &:focus {
        outline: none;
    }

    &::after {
        content: "";
        position: absolute;
        background: $font-color-blue;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        z-index: 1;
        top: -1px; // look at $sliderSteps variable, that many shadows needs to be done
        box-shadow: (168px / 2 * 1) 0 0 $font-color-blue, (168px / 2 * 2) 0 0 $font-color-blue;
    }

    // SLIDER THUMB
    // Slider thumb for Chrome
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-top: -9px; // You need to specify a margin in Chrome
        cursor: ew-resize;
        background: $font-color-blue url(../blog-img/pricing/2arrows.png) 45% 50% no-repeat;
        position: relative;
        z-index: 1;
    }

    // Same stuff for Firefox
    &::-moz-range-thumb {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: none;
        cursor: ew-resize;
        background: $font-color-blue url(../blog-img/pricing/2arrows.png) 45% 50% no-repeat;
        position: relative;
        z-index: 1;
    }

    // Same stuff for IE
    &::-ms-thumb {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: ew-resize;
        background: $font-color-blue url(../blog-img/pricing/2arrows.png) 45% 50% no-repeat;
        position: relative;
        z-index: 1;
    }

    // SLIDER TRACK
    //Slider track for Chrome
    &::-webkit-slider-runnable-track {
        height: 1px;
        width: $sliderWidth;
        margin-left: -8px;
        background: url(/blog-img/pricing/slider4.png) center center no-repeat;
        background-size: 100%;
        cursor: pointer;
    }

    // Firefox
    &::-moz-range-track {
        height: 1px;
        width: $sliderWidth;
        margin-left: -8px;
        background: url(/blog-img/pricing/slider4.png) center center no-repeat;
        background-size: 100%;
        cursor: pointer;
    }

    // IE
    &::-ms-track {
        height: 1px;
        width: $sliderWidth;
        margin-left: -8px;
        background: url(/blog-img/pricing/slider4.png) center center no-repeat;
        background-size: 100%;
        cursor: pointer;
    }

    &::-ms-fill-lower {
        height: 1px;
        width: $sliderWidth;
        margin-left: -8px;
        background: url(/blog-img/pricing/slider4.png) center center no-repeat;
        background-size: 100%;
        cursor: pointer;
    }

    &::-ms-fill-upper {
        height: 1px;
        width: $sliderWidth;
        margin-left: -8px;
        background: url(/blog-img/pricing/slider4.png) center center no-repeat;
        background-size: 100%;
        cursor: pointer;
    }
}

// classes for slider's blue line which changes length based on the value
// if you're going to change number of slider's steps add or delete depending on your need pseudo-element for corresponging number's class, then change pricing.js file
.one::before {
    content: " ";
    width: $sliderWidth/$sliderSteps;
    position: absolute;
    height: 2px;
    background-color: $font-color-blue;
}

.two::before {
    content: " ";
    width: ($sliderWidth/$sliderSteps) * 2;
    position: absolute;
    height: 2px;
    background-color: $font-color-blue;
}
