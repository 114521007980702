.p404 {
    &-container {
        text-align: center;
        background: url('/blog-img/bg_404.png') center center no-repeat;
        background-size: cover;
        min-height: 890px;
        margin-bottom: -80px;
    }
    &-header {
        font-weight: 300;
    }
    &-text {
        max-width: 300px;
        margin: 0 auto;
    }
    &-homepage-button {
        display: inline-block;
        background: $font-color-blue;
        color: $background-color;
        font-size: $font-size-button;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 16px 26px;
        font-weight: 400;
        border-radius: 3px;
        margin: $spacer auto;
        transition: background 0.2s;
        &:hover,
        &:active,
        &:focus {
            color: $font-color-black;
        }
    }
}