.section-educational-pricing {
  box-shadow: 0 22px 34px 0 rgba(0,0,0,0.05);
  border: 1px solid $border-light-gray;

}
.section-educational-pricing-background-image {
  background: url(/blog-img/academy/Visual.png) no-repeat center center;
  background-size: cover;
  background-position: 0 150%;
  height: 820px;
  @include media-breakpoint-down(md) {
      background-position: center center;
      background-size: 750px;
      margin-bottom: -150px;
  }
  .educational-card {
    display: flex;
    justify-content: space-evenly;
    margin: $spacer-content auto;
    border: 1px solid $border-light-gray;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    width: 750px;
    height: 250px;
    border-radius: 6px;
    margin-top: 95px;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        max-width: 90vw;
        height: auto;
    }
    .educational-card-body {
      text-align: center;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 375px;
      @include media-breakpoint-down(md) {
        width: auto;
      }
      &:first-child {
         border-right:1px solid $border-light-gray;
      }
      &-title {
        font-size: $font-size-card-title;
        line-height: 25px;
        text-align: center;
        font-weight: 500;
        margin:25px 0 15px 0;
      }
      &-content {
        font-size: $font-size-card-content;

      }
      .educational-price {
        display: flex;
        justify-content: center;
        font-size: 110px;
        font-weight: 100;
        line-height: 129px;
        color: $font-color-black;
        &::before {
            content: "$ ";
            font-size: $font-size-heading;
            font-weight: 100;
            margin: 0 $spacer-small;
        }
        &::after {
            content: "/ mo";
            font-size: $font-size-list-title;
            font-weight: 300;
            margin: 0 $spacer-small;
        }
      }
      .educational-button {
        background-color: $font-color-blue;
        display: block;
        color: $background-color;
        font-size: $font-size-button;
        font-weight: 500;
        letter-spacing: 1px;
        border: none;
        border-radius: 4px;
        height: 50px;
        width: 148px;
        margin: $spacer-small auto;
        cursor: pointer;
      }
    }
  }
}
