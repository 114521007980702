.blog-post-container {
    margin: 50px auto;
    max-width: $container-width;

    &.blog-post-see-also {
        max-width: 700px
    }
}

.post-container {
    margin: $header-height auto 0 auto;
}

article {
    .blog-post {
        &-intro {
            display: flex;
            justify-content: flex-start;
            position: relative;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        &-intro-link {
            position: absolute;
            left: 50px;
            top: 45px;
            text-transform: uppercase;
            font-size: $font-size-button;
            color: $background-color;
            line-height: 18px;
            padding-bottom: 2px;
            font-weight: 500;

            .ico_arrow {
                margin-right: $spacer-xsmall;
            }
        }

        &-featured-image.tall {
            flex-basis: 50%;
            background-size: cover;
            height: 550px;
            -webkit-filter: brightness(0.8);
            -moz-filter: brightness(0.8);
            -o-filter: brightness(0.8);
            -ms-filter: brightness(0.8);
            filter: brightness(0.8);

            img {
                display: none;
            }

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                padding: 150px $spacer-small;
            }
        }

        &-tags-below {
            display: flex;
            flex-direction: row;
            max-width: 700px;
            margin: 0 auto;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                margin: 0 $spacer-small;
            }

            .share_comments {
                display: flex;
                img {
                    margin-left: $spacer-small;
                    max-width: 22px;
                    max-height: 22px;

                }

                .share,
                .comment {
                    color: $font-color-gray;
                    font-size: $font-size-content;
                    line-height: 22px;
                    margin-left: $spacer-small;
                    font-weight: 400;
                }
            }

        }

        &-header {
            padding: $spacer 0 0 70px;
            flex-basis: 50%;
            position: relative;
            max-width: 650px;

            .ico_clock img {
                margin-right: $spacer-xsmall;
            }

            @include media-breakpoint-down(lg) {
                padding: $spacer-small $spacer-small 5px $spacer-small;
            }

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                max-width: 700px;
                margin: 0 auto;
            }

            .summary {
                &-time {
                    color: $font-color-gray;
                    font-size: $font-size-button;
                    line-height: 15px;
                    font-weight: 400;
                }

                &-blog-post-meta {
                    margin-top: 35px;
                    margin-bottom: 15px;
                    font-size: 14px;

                    @include media-breakpoint-down(md) {
                        margin-top: 25px;
                        margin-bottom: 25px;
                    }
                }

                &-blog-post-meta-author a {
                    display: flex;
                    flex-direction: row;
                }

                &-blog-post-meta-author-img {
                    align-self: center;

                    img {
                        max-width: 52px;
                        width: 52px;
                        height: 52px;
                        border-radius: 26px;
                        border: 1px solid $border-color;
                    }
                }

                &-blog-post-meta-author-info {
                    display: flex;
                    flex-direction: column;
                    margin-left: $spacer-small;
                    font-size: 14px !important;
                    color: $font-color-black;
                }

                &-blog-post-meta-author-name {
                    font-weight: 400;
                    &:hover{
                        color:$font-color-blue;
                    }
                }

                &-blog-post-meta-author-position {
                    font-weight: 300;

                    p {
                        margin: 0;
                    }
                }

                &-tags {
                    display: flex;
                    flex-wrap: wrap;

                    @include media-breakpoint-down(md) {
                        bottom: 0px;
                    }
                }
            }
        }

        &-header-title {
            color: $font-color-black;
            font-size: $font-size-heading;
            font-weight: 300;
            line-height: 73px;
            margin-top: $spacer-small;

            @include media-breakpoint-down(lg) {
                font-size: $font-size-title;
                line-height: 45px;
            }
        }

        &-reading-time {
            color: $font-color-gray;
            font-size: $font-size-button;
            line-height: 15px;
            padding-left: $spacer-small;
            font-weight: 400;
        }

        &-category {
            color: $font-color-blue;
            font-size: $font-size-teaser-name;
            letter-spacing: 2px;
            line-height: 14px;
            text-transform: uppercase;
            font-weight: 400;
            margin-top: 20px;
            display: block;
            padding: 0;

        }

        &-category-header {
            margin-top: 70px;
        }

        &-comments {
            max-width: 700px;
            margin: 2*$spacer auto 0 auto;
        }

        &-newsletter {
            max-width: 700px;
            margin: 70px auto 0 auto;
            position: relative;
            background-size: cover;
            background-position: 50% 0;
            height: 330px;
            display: block;
            @include media-breakpoint-down(sm) {
                margin: 70px $spacer-small 0 $spacer-small;
                
            }

            .newsletter_content {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                padding-left: 60px;
                padding-top: 50px;
                @include media-breakpoint-down(sm) {
                    padding-left: $spacer; 
                }
                @include media-breakpoint-down(xs) {
                    padding-left: $spacer-small; 
                }
            }

            .newsletter_title {
                font-size: 30px;
                line-height: 35px;
                font-weight: 400;
                margin-bottom: 10px;
                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }
            }

            .newsletter_subtitle {
                font-size:$font-size-list-title;
                color: $font-color-gray;
                margin-bottom: 10px;
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }

            .newsletter_email {

                margin-bottom: 30px;
                padding: 10px 0;
                border: none;
                background: transparent;
                border-bottom: 2px solid $border-color;
                width: 230px;

                &::placeholder {
                    line-height: 35px;
                    color: $font-color-black;
                    font-size: $font-size-content;
                }
            }

            .newsletter_button {
                align-self: baseline;
                margin: 0;
            }

        }

        &-main {
            border-bottom: 1px solid $border-light-gray;
            margin-top: 2*$spacer;
            max-width: $container-width;
            p:not(.rek-banner-content) a,
            span:not(.rek-banner-content) a {
                text-transform: uppercase;
                color: $font-color-black;
                font-weight: 500;
                text-decoration: underline;
                letter-spacing: 1px;
                font-size: $font-size-button;
                &:hover {
                    color: $font-color-blue;
                }
            }

            @include media-breakpoint-down(md) {
                margin-top: $spacer;
            }
        }

        &-main-content {
            max-width: 700px;
            margin: 0 auto;
            color: $font-color-gray;
            font-size: $font-size-list-title;
            font-weight: 400;
            line-height: 30px;
            border-bottom: 1px solid #F2F2F2;
            padding-bottom: $spacer;
            position: relative;

            @include media-breakpoint-down(md) {
                padding-left: 15px;
                padding-right: 15px;
            }



            h2 {
                font-size: $font-size-teaser-title;
                font-weight: 300;
            }

            strong {
                font-weight: 400;
            }

            h3 {
                font-size: 25px;
                line-height: 1.2;
                font-weight: 400;
                color: $font-color-black;
                margin-top: 60px;
                margin-bottom: 30px;
            }

            h4 {
                font-size: 20px;
                font-weight: 400;
            }
        }
    }
}
.social_ico {
    position: absolute !important;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    left: 40px;
    margin-top: -50px;
    &_img {
        width: 62.64px;
        height: 62.64px;
        border-radius: 50%;
        border: 1.32px solid $border-color-gray;
        margin-bottom: 7px;
        text-align: center;
        line-height: 68px;

    }

    @include media-breakpoint-down(lg) {
        left: -100px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }

}
.summary {
    &-tags {
        display: flex;
        cursor: pointer;
        bottom: $spacer;


        @include media-breakpoint-down(md) {
            bottom: 0
        }

    }

    &-tags-title {
        font-size: 14px;
        padding: $spacer-xsmall $spacer-xsmall 0 0;
        margin-top: $spacer-xsmall;
    }

    &-tags-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        margin: 0 0 $spacer-small 0;
    }

    &-tags-list-element {
        margin-right: $spacer-xsmall;
        margin-top: $spacer-xsmall;
        border: 1px solid #E6E6E6;
        border-radius: 5px;
        background-color: #FFFFFF;

        &:hover {
            box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.07);
        }

        a {
            color: $font-color-gray;
            font-size: 14px;
            line-height: 16px;
            padding: 0 5px;
            cursor: pointer;
        }
    }
}

.related-posts {
    border: 1px solid $border-light-gray;
    background-color: #F8F8F8;
    margin-bottom: -85px;

    h3 {
        text-align: center;
        margin-top: 60px;
    }

    &__content {
        max-width: $container-width;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    &__item {
        flex-basis: 30%;
        position: relative;
        padding: $spacer-small 0 50px 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;


        &:nth-of-type(1n+4) {
            display: none;
        }

        &:hover {
            box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.07);
        }
        h4 {
            font-size: $font-size-teaser-title;
            line-height: 35px;
            color: #333;
            font-weight: 300;
            margin: 10px 0 $spacer 0;
            width: 275px;
            &:hover {
                color: $font-color-blue;
            }
        }

    }

    &__image {
        flex-basis: 50%;
        background-size: cover;
        background-position: 50% 20%;
        height: 275px;
        width: 275px;
        border-radius: 5px;
    }

    .summary-read-more.blog-list {
        position: absolute;
        bottom: 35px;
        margin-top: 0;
        width: 275px;
    }

    .blog-post-category {
        width: 275px;
    }
}

.post-navigation {

    &__content {
        margin: 1.5em 0 1em;
        cursor: pointer;
        display: flex;
        margin: 0;
    }

    &__item {
        flex: 1 1 50%;
        display: flex;
        margin: 1em 0 0.5em;
        align-items: flex-start;
        padding-right: 0.5em;
    }

    &__item a {
        color: #333;
        font-size: $font-size-card-content;
    }


    &__item h4 {
        margin-top: 0;
        font-size: $font-size-card-content;
        line-height: 23px;
    }

    &__title {
        font-weight: 400;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__item-thumbnail {
        flex: 0 0 auto;
    }

    &__item-thumbnail img {
        margin-right: 20px;
    }

    &__item-thumbnail * {
        vertical-align: middle;
    }

    &__item-thumbnail .fa {
        font-size: 18px;
    }

    &__item-thumbnail .fa-angle-double-left {
        margin-right: 10px;
        margin-left: 10px;
    }

    &__item-thumbnail .fa-angle-double-right {
        margin-left: 10px;
        margin-right: 10px;
    }

    &__item-thumbnail .fa-angle-double-right {
        display: none;
    }

    &__item-thumbnail img {
        border-radius: 50%;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__item-content {
        margin-top: -10px;

        @include media-breakpoint-down(md) {
            margin-top: 0;
            line-height: 1.9;
        }
    }

    &__item+.post-navigation__item {
        text-align: right;
        flex-direction: row-reverse;
        padding-right: 0;
        padding-left: 0.5em;
    }

    &__item+.post-navigation__item .post-navigation__item-thumbnail img {
        margin-right: 0;
        margin-left: 20px;
    }

    &__item+.post-navigation__item .post-navigation__item-thumbnail .fa-angle-double-left {
        display: none;
    }

    &__item+.post-navigation__item .post-navigation__item-thumbnail .fa-angle-double-right {
        display: inline-block;
    }
}