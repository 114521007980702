.section-knowledge-base {
    margin: 120px 0;
    @include media-breakpoint-down(sm) {
        margin: 70px 0;
    }
    &-subtitle {
        color: $font-color-violet;
        text-align: center;
        font-size:$font-size-content;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 16px;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }
    }
   h2 {
       text-align: center;
        position: relative;
        margin-top: 20px;
        &:after {
            content: "";
            width: 100px;
            height: 3px;
            background-color: $font-color-blue;
            display: block;
            position: absolute;
            margin: 0 auto;
            border-top: 2px solid transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
        }
        @include media-breakpoint-down(sm) {
            font-size: $font-size-title;
            font-weight: 300;
        }
    }
    a:hover {
        color: $font-color-violet;
    }
}
.section-courses {
    a:hover {
        color: $font-color-green;
    }
}
