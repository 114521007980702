.features-navigation {
    box-shadow: 0 22px 34px 0 rgba(0,0,0,0.04);
    border: 1px solid $slider-gray;
    background-color: $background-color;
    display: block;
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 120px;
    margin-bottom: 100px;
    transition: 0.2s ease 0s;
    @include media-breakpoint-down(lg) {
        display: none;
    }

    &_list {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        a {
            text-decoration: none;
            color: $font-color-black;
            font-size: $font-size-content;
            color:  $font-color-black;
            white-space: nowrap;
            padding-top: 10px;
            line-height: 75px;
            border-bottom: 2px solid transparent;
            &.active {
                border-bottom: 2px solid $font-color-blue;
            }
        }
    }
    &.nav-up {
        top:0;
    }
}

.header_features {
    .site-header {
        box-shadow: none;
    }
}