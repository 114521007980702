    .siteHeaderHome {
        top: 0;
        width: 100%;
        color: $background-color;
        z-index: 10000;

        &.active {
            position: fixed;
        }

        &-navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $spacer 20px;
            max-width: 1170px;
            margin-right: 25px;
            margin: 0 auto;
            position: relative;
        }

        &-navigation-hamburger-wrapper {
            background-color: black;
            display: none;
            position: fixed;
            z-index: 990;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            overflow: hidden;
        }

        &-navigation-hamburger-layout {
            height: 100vh;
            max-width: $container-width;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            text-align: right;
            position: relative;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        &-navigation-hamburger {
            color: white;
            padding: 0 45px;
            display: flex;
            flex-direction: column;
            list-style: none;
            margin-top: 70px;

            .fala_1,
            .fala_2 {
                position: absolute;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .fala_1 {
                left: -550px;
                bottom: -150px;
                transform: scale(0.7);
                z-index: 100000;
            }

            .fala_2 {
                left: 0px;
                bottom: -120px;
                transform: scale(0.7);
            }

            .fala_1.animated_wave_in {
                animation: wave_in;
                animation-duration: 0.7s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

                @keyframes wave_in {
                    0% {
                        left: -550px;
                        bottom: -150px;
                    }
                    100% {
                        left: -500px;
                        bottom: -170px;
                    }
                }
            }

            .fala_1.animated_wave_out {
                animation: wave_out;
                animation-duration: 0.7s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;

                @keyframes wave_out {
                    0% {
                        left: -500px;
                        bottom: -170px;
                    }
                    100% {
                        left: -550px;
                        bottom: -150px;
                    }
                }
            }

            .fala_2.animated_wave_in {
                animation: wave_in_2;
                animation-duration: 0.7s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

                @keyframes wave_in_2 {
                    0% {
                        left: 0px;
                    }
                    100% {
                        left: -50px;
                    }
                }
            }

            .fala_2.animated_wave_out {
                animation: wave_out_2;
                animation-duration: 0.7s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;

                @keyframes wave_out_2 {
                    0% {
                        left: -50px;
                    }
                    100% {
                        left: 0px;
                    }
                }
            }
        }


        &-navigation-hamburger-item {
            color: $background-color;
            font-size: $font-size-home-menu;
            font-weight: 100;
            letter-spacing: 2.73px;
            line-height: 60px;
            text-align: right;

            &.pages {
                color: $font-color-blue;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 45px;
            }

            &:hover {
                .siteHeaderHome-navigation-hamburger-link {
                    border-bottom: 1px solid $font-color-blue;
                    color: $background-color;
                }
            }

            &.home img,
            &.features img,
            &.pricing img,
            &.academy img {
                opacity: 0;
                position: absolute;
                bottom: 0;
                left: 200px;
                max-height: 750px;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &.pricing img {
                transform: scale(1.2);
            }

            &.home.animated_character_in img,
            &.features.animated_character_in img,
            &.academy.animated_character_in img {
                animation: rotate-slidein;
                animation-duration: 0.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

                @keyframes rotate-slidein {
                    0% {
                        transform: translateY(1000px) rotate(-30deg);
                        opacity: 0;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                    }
                }
            }

            &.home.animated_character_out img,
            &.features.animated_character_out img,
            &.academy.animated_character_out img {
                animation: rotate-slideout;
                animation-duration: 0.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;

                @keyframes rotate-slideout {
                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        transform: translateY(1000px) rotate(-30deg);
                        opacity: 0;
                    }
                }
            }
            &.pricing.animated_character_in img {
                animation: rotate-slidein_pricing;
                animation-duration: 0.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                @keyframes rotate-slidein_pricing {
                    0% {
                        transform: translateY(1000px) rotate(20deg);
                        opacity: 0;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                    }
                }
            }
            &.pricing.animated_character_out img {
                animation: rotate-slideout_pricing;
                animation-duration: 0.5s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;

                @keyframes rotate-slideout_pricing {
                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        transform: translateY(1000px) rotate(20deg);
                        opacity: 0;
                    }
                }
            }
        }

        &-navigation-hamburger-link {
            color: $background-color;
            border-bottom: 1px solid $font-color-gray;
            display: inline-block;
            padding-top: 24px;

            &.active {
                color: $background-color;
                border-bottom: 1px solid $font-color-gray;
            }
        }

        &-navigation-below {
            list-style: none;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 0 45px;

            @include media-breakpoint-down(md) {
                justify-content: center;
            }
        }

        &-navigation-layer-menu-cover {
            display: block;
        }

        &-navigation-layer-menu-cover,
        &-navigation-layer-menu-cover-active {
            position: absolute;
            right: 22px;
            top: -5px;
            font-size: $font-size-base;
            font-weight: 500;
            letter-spacing: 1px;
            color: $background-color;

            &-active {
                display: none;
            }

            &.active {
                right: 40px;
                top: 2px;
            }

            @include media-breakpoint-down(sm) {
                display: none !important;
            }

            &:hover {
                color: $font-color-blue;
            }
        }

        &-navigation-layer-menu {
            position: relative;
            cursor: pointer;
            z-index: 100000;
            padding: 10px;
            margin-right: $spacer;

            &-icon {
                position: absolute;
                width: 30px;
                height: 2px;
                background-color: white;
                top: 0px;
                z-index: 1050;
                right: -27px;

                input {
                    display: block;
                    width: 40px;
                    height: 32px;
                    position: absolute;
                    top: -11px;
                    right: -9px;
                    cursor: pointer;
                    border: 2px solid red;
                    opacity: 0;
                    /* hide this */
                    z-index: 2;
                }

                &:after,
                &:before {
                    content: '';
                    width: 100%;
                    height: 5px;
                    background-color: transparent;
                    position: absolute;
                }

                &:after {
                    top: 10px;
                    left: 0;
                }

                &:before {
                    top: 20px;
                    left: 0;
                }

                @include media-breakpoint-down(sm) {
                    top: 3px;
                }

                &.active {
                    transform: rotate(45deg);
                    top: 5px;
                    right: -25px;

                    input {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(90deg);
                        right: 0px;
                        top: 1px;

                        @include media-breakpoint-down(sm) {
                            top: 0px;
                        }
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 2px;
                    background-color: white;
                    right: 0px;
                    top: 10px;
                    z-index: 1050;
                }
            }

            &:hover {
                color: $font-color-blue;

                .siteHeaderHome-navigation-layer-menu-cover,
                .siteHeaderHome-navigation-layer-menu-cover-active {
                    color: $font-color-blue;
                }

                .siteHeaderHome-navigation-layer-menu-icon {
                    background-color: $font-color-blue;
                    &.active {
                        position: fixed;
                        right: 25px;
                        top: 54px;
                    }

                    &:after {
                        background-color: $font-color-blue;
                    }
                }
            }
        }

        &-navigation-logo {
            margin-top: 6px;

            @include media-breakpoint-down(sm) {
                max-width: 123px;
            }
        }

        &-navigation-main-menu {
            margin-top: 10px;
            line-height: 18px;
        }

        &-navigation-main-menu-list {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &-navigation-main-menu-list-item {
            display: flex;
           flex-direction: row;
            position: absolute;
            right: 150px;
            top: 49px;
        }

        &-navigation-main-menu-list-item.login {
            border-left: 1px solid $background-color;
            top: 49px;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &:last-child {
                border: 1px solid #006DFF;
                padding: 12px;
                border-radius: 5px;
            }

            a {
                padding: 0 30px;
                background: none !important;
                color: white;
                text-transform: uppercase;
                font-size: $font-size-base;
                font-weight: 500;
                letter-spacing: 1px;

                &:hover {
                    color: $font-color-blue;
                }
            }
        }
    }
    .below-item-link {
        font-weight: 500;
        text-transform: uppercase;
        color: $background-color;
        letter-spacing: 1px;
        margin-right: $spacer-small;
        font-size: $font-size-base;
        line-height: 52px;

        &.active a {
            color: $background-color;
            border-bottom: none;
            font-weight: 500;
        }

        &:hover,
        &:focus {
            color: $font-color-blue
        }
    }