.section-qa-pricing {
    background-color: $background-color;
    border: 1px solid $border-light-gray;
    text-align: center;
    box-shadow: inset 0 22px 34px 0 rgba(0, 0, 0, 0.04);
    border-bottom: none;

    .section-qa-pricing-header {
        margin-top: 100px;
    }
    .pre-header {
        color: $font-color-blue;
        font-size: $font-size-base;
        letter-spacing: 2px;
        font-weight: normal;
        text-transform: uppercase;
    }
    h4 {
        font-size: $font-size-heading;
        font-weight: 100;
        color: $font-color-black;
        margin-top: 10px;
        margin-bottom: 30px;
        line-height: 64px;
        @include media-breakpoint-down(md) {
            font-size: $font-size-title;
            line-height: 45px;
            font-weight: 300;
        }
    }
    .qa-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 872px;
        margin: 130px auto;
        max-width: 80vw;
        @include media-breakpoint-down(md) {
            margin: 50px auto;
        }
        .qa-set {
            width: 750px;
            transition-duration: 0.1s;
            cursor: pointer;
            max-width: 80vw;
            &-question {
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid #f5f5f5;
                position: relative;
                max-width: 100%;
                .qa-question {
                    font-size: $font-size-card-content;
                    font-weight: 400;
                    color: $font-color-black;
                }
                > p {
                    max-width: 85%;
                    text-align: left;
                }
                .show-more::after {
                    content: "";
                    height: 2px;
                    width: 18px;
                    background-color: black;
                    position: absolute;
                    right: 5px;
                    top: 33px;
                }
                .show-more::before {
                    content: "";
                    height: 18px;
                    width: 2px;
                    background-color: black;
                    position: absolute;
                    right: 13px;
                    top: 25px;
                }
            }
            &-answer {
                font-weight: 300;
                font-size: 2 + $font-size-content;
                color: $font-color-gray;
                display: none;
            }
        }
        .open {
            width: 872px;
            padding: 20px 50px;
            border: 1px solid #ececec;
            border-radius: 5px;
            background-color: $background-color;
            box-shadow: 0 22px 24px 0 rgba(0, 0, 0, 0.03);
            @include media-breakpoint-down(md) {
                padding: $spacer-small;
            }
            .qa-set-question {
                border: none;
                width: 750px;
                .qa-question {
                    font-size: $font-size-footer-header;
                    font-weight: 500;
                    color: $font-color-black;
                }
                .show-more::before {
                    content: "";
                    height: 18px;
                    width: 2px;
                    background-color: transparent;
                    position: absolute;
                    right: 13px;
                    top: 30px;
                }
            }
            .qa-set-answer {
                display: block;
                text-align: left;
                max-width: 93%;
                padding-bottom: $spacer-small;
                a {
                    @extend .read-more-link;
                    color: $font-color-black;
                }
            }
        }
    }
    &.sticky-section {
        border-bottom: none;
        box-shadow: none;
    }
}
