.taxonomy {
    &.container {
        margin-right: auto;
        margin-left: auto;
        padding-top: 124px;
        max-width: 700px;
    }

    &-box__header {
        font-weight: 400;
    }

    &-box {
        margin-top: 40px;
        margin-bottom: 40px;
        border: 1px solid #eee;
        border-radius: 30px;
        padding: 15px 30px;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05);
    }

    &-box__title {
        margin: $spacer-small 0;
        font-weight: 400;
    }

    &-box__image {
        img {
            border-radius: 50%;
        }

    }

    .summary {
        border-bottom: 1px solid $border-light-gray;
        padding: $spacer $spacer-small;

        &-tags {
            padding-top: 0;
        }

        &-title {
            margin-top: $spacer-small;

            a {
                color: $font-color-black;
            }
        }

        &-blog-post-meta {
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 14px;
        }

        &-blog-post-meta-author a {
            display: flex;
            flex-direction: row;
        }

        &-blog-post-meta-author-img {
            align-self: center;

            img {
                max-width: 52px;
                width: 52px;
                height: 52px;
                border-radius: 26px;
                border: 1px solid $border-color;
            }
        }

        &-blog-post-meta-author-info {
            display: flex;
            flex-direction: column;
            margin-left: $spacer-small;
            font-size: 14px !important;
            color: $font-color-black;
        }

        &-blog-post-meta-author-name {
            font-weight: 400;
        }

        &-blog-post-meta-author-position {
            font-weight: 300;

            >p {
                margin: 0;
            }
        }

        .blog-post-level {
            color: $background-color;
            background-color: $font-color-green;
            border-radius: 10px;
            font-size: $font-size-subtitle;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: center;
            padding: $spacer-xsmall $spacer-small;

            &.blue {
                background-color: $font-color-blue;

            }
        }
    }

    .level-terms {
        .summary-title {
            color: #fff;
            background-color: $font-color-green;
            border-radius: 10px;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: center;
            padding: 5px 15px;
        }

        .summary:first-child .summary-title {
            background-color: $font-color-blue;
        }
    }
}
.summary__title.authors,.summary__content.authors {
        text-align: center;
        padding:$spacer 0;
        margin: 0;
}
.summary__title.authors{
    padding-bottom: 0;
}
.summary.taxonomy.container {
    padding: 0;
}
