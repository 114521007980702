.investor-relations {
    text-align: center;
    padding: 0 0 50px;
    @include media-breakpoint-up(lg) {
        display: flex;
        padding: 0 5% 150px;
    }
    justify-content: center;
    flex-wrap: wrap;
    &__heading {
        font-size: $font-size-heading;
        color: $font-color-black;
        position: relative;
        margin-bottom: 60px;
        flex-basis: 100%;
        &::after {
            content: " ";
            width: 100px;
            height: 3px;
            background-color: $font-color-blue;
            display: block;
            position: absolute;
            margin: 0 auto;
            border-top: 2px solid transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
        }
    }
    &__text {
        color: $font-color-gray;
    }
    &__second-language {
        color: $font-color-black;
        font-size: $font-size-base;
        text-decoration: underline;
        flex-basis: 100%;
        margin-top: $spacer;
    }
}
.info-box {
    max-width: 557px;
    background-color: $background-color;
    border: 1px solid $border-color-gray;
    border-radius: 5px;
    box-shadow: 0 22px 24px 0 rgba(0,0,0,0.05);
    text-align: left;
    margin: $spacer-content*2 auto 0;
    padding:  30px 15px 60px;
    @include media-breakpoint-up(lg) {
        padding:  30px 60px 60px;
    }
    @include media-breakpoint-down(md) {
        max-height: none;
        margin: 0 auto;
        text-align: center;
    }
    .phone-number {
        white-space: nowrap;
    }
    &__wrapper {
        margin: $spacer-small $spacer-small;
        @include media-breakpoint-up(lg) {
            width: 48%;
            margin: 0 $spacer-xsmall;
        }
    }
    &__notices {
        display: flex;
        flex-direction: column-reverse;
    }
    &__heading {
        font-size: 30px;
        line-height: 30px;
        color: $font-color-blue;
        font-weight: 400;
        margin: 0 0 $spacer-small;
        @include media-breakpoint-down(sm) {
            font-size: 5vw;
            line-height: 5vw;
        }
    }
    &__description {
        font-size: $font-size-base;
        ul > li {
            font-size: $font-size-base;
        }
    }
    &__text {
        color: $font-color-black;
    }
    &__address-and-contact {
        display: flex;
        @include media-breakpoint-down(lg) {
            justify-content: center;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    &__left-column {
        margin-right: $spacer;
    }
    &__label {
        font-size: $font-size-base;
        color: $font-color-gray;
    }
    &__data {
        color: $font-color-black;
        font-weight: 400;
        margin: 0;
        line-height: 28px;
    }
    &__legal-information {
        font-size: 12px;
        font-weight: 400;
    }
    &__legal-data {
        color: $font-color-black;
        margin: 0;
        line-height: 19px;
    }
    &__legal-label {
        color: $font-color-gray;
    }
    &__website-link {
        color: $font-color-black;
        text-decoration: underline;
    }
}