.section-courses {
    padding: 120px 0;
    border-top:1px solid #F2F2F2;
    background-color: #FFFFFF;
    box-shadow: 0 22px 34px 0 rgba(0,0,0,0.04) inset;
    &-subtitle {
        color:$font-color-green;
        text-align: center;
        font-size:$font-size-content;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 16px;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }
    }
    &-title {
        text-align: center;
        position: relative;
        margin-top: 20px;
        &::after {
            content: "";
            width: 100px;
            height: 3px;
            background-color: $font-color-green;
            display: block;
            position: absolute;
            margin: 0 auto;
            border-top: 2px solid transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
                }
        @include media-breakpoint-down(sm) {
            font-size: $font-size-title;
            font-weight: 300;
        }
    }
}