.academy-section-teaser {

    &-content,
    &-content-2,
    &-content-3,
    &-content-4 {
        text-align: center;
        background-color: $background-color-light-gray;
        max-width: $container-width;
        margin: 70px auto;
        padding: $spacer 0;
        border: 1px solid #F2F2F2;
        border-radius: 5px;
    }

    &-content-2,
    &-content-4 {
        background-color: $background-color;
        padding: 0;
        border: none;
    }

    &-title {
           color: $background-color-violet;
           margin-bottom: $spacer-small;
        &.courses {
            color: $font-color-green;
        }
    }

    &-subtitle {
        margin: $spacer-small $spacer-small;
    }

    &-see-all-links,
    &-blog-list {
        margin-top: $spacer;
        display: inline-block;
        color: $font-color-black;
        font-size: $font-size-button;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        border-bottom: 1px solid $border-color;
    }
    &-see-all-links {
        margin-top: $spacer+5px;
        margin-bottom: $spacer;
    }
    &-see-all-link {
        color: $font-color-black;

        &.courses {
            &:hover {
                color: $font-color-green !important;
            }
        }

        &:last-child {
            display: none;
        }

        &.see {
            display: none;
        }
    }

    &-blog-list {
        display: block;
        border-bottom: none;
        margin-top: -$spacer;
        margin-bottom: $spacer;
    }

    &-shortcut_articles {
        display: flex;
        justify-content: baseline;
        flex-wrap: wrap;
        margin: 0 $spacer;

        @include media-breakpoint-down(sm) {
            margin: 0 $spacer-small;
        }

        .summary {
            margin-right: 15px;
            margin-left: 15px;
            flex-basis: 30%;
            background-color: $background-color;
            padding: 40px;
            margin-top: 40px;
            text-align: left;
            position: relative;
            border: 1px solid $border-light-gray;
            border-radius: $spacer-xsmall;
            &-description {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                line-height: 1.7;     /* fallback */
                max-height: 100px;      /* fallback */
                -webkit-line-clamp: 4; /* number of lines to show */
                -webkit-box-orient: vertical;
            }

            @include media-breakpoint-down(md) {
                flex-basis: 45%;
                margin-top: 35px;
                padding: 20px;
            }

            @include media-breakpoint-down(xs) {
                flex-basis: 100%;
            }

            &:nth-child(n+4) {
                display: none;
            }

            &.checked {
                display: block;
            }

            &:hover {
                box-shadow: 0 22px 24px 0 rgba(0, 0, 0, 0.05);

                .summary-read-more-link {
                    display: block;
                }
            }

            .blog-post-level {
                color: $background-color;
                background-color: $font-color-green;
                border-radius: 10px;
                font-size: $font-size-subtitle;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-align: center;
                padding: $spacer-xsmall $spacer-small;

                &.blue {
                    background-color: $font-color-blue;

                }
            }

            &-blog-post-meta {
                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 12px;
            }

            &-blog-post-meta-author a {
                display: flex;
                flex-direction: row;
            }

            &-blog-post-meta-author-img {

                img {
                    object-fit: cover;
                    width: 32px;
                    height: 32px;
                    border-radius: 16px;
                    border: 1px solid $border-color;
                }
            }

            &-blog-post-meta-author-info {
                display: flex;
                flex-direction: column;
                margin-left: $spacer-small;
                font-size: $font-size-teaser-name;
                color: $font-color-black;
                margin-top: -5px;
            }

            &-blog-post-meta-author-name {
                margin-bottom: -3px;
                font-weight: 400;
            }

            &-blog-post-meta-author-position {
                font-weight: 300;

                p {
                    margin: 1px 0;
                }

            }

            &-title {
                font-size: $font-size-teaser-title;
                line-height: 29px;
                font-weight: 400;
                margin-top: 20px;

                a {
                    color: $font-color-black;

                    &:hover {
                        color: $font-color-violet;
                    }
                }
            }

            &-content {
                font-size: $font-size-content;
                color: #666666;
                font-weight: 400;
                padding-bottom: 40px;

                @include media-breakpoint-down(md) {
                    padding-bottom: 50px;

                }
            }

            &-read-more-link {
                display: none;
                position: absolute;
                bottom: $spacer;
                left: 40px;
                font-size: $font-size-button;
                text-transform: uppercase;
                color: $font-color-black;
                font-weight: 500;
                letter-spacing: 1px;
                border-bottom: 1px solid $border-color;
                cursor: pointer;

                @include media-breakpoint-down(md) {
                    bottom: 30px;
                    left: 20px;

                }
            }
        }
    }

    &-shortcut_articles.courses {
        .summary-title  a {
            &:hover {
                color: $font-color-green;
            }
        }
    }

    &-shortcut_articles.checked {
        .summary {
            display: block;
        }
    }
}

.section-courses .academy-section-teaser {
    &-see-all-links:hover {
        a {
            color: $font-color-green !important;
        }
    }
}