.doc-list {
    flex-basis: 25%;
    margin-right: $spacer+$spacer-xsmall;
    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }

    &-label {
        font-weight: 400;
        font-size: $font-size-list-title+4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &-arrow {
            height: 20px;
            vertical-align: middle;
            transition: 0.4s;
            margin-left: 5px;
        }
        &.expanded &-arrow {
            transform: rotate(-180deg);;
        }
    }

    &-container {
        max-width: 1400px;
        margin: 150px auto;
        padding: 0 40px;
    }

    &-search {}

    &-menu {
        @extend .blog-list-menu;
    }

    &-nav {
        @extend .blog-list-nav;
        display: block;
        margin: 0;
        padding: 0;
    }

    &-nav-item {
        @extend .blog-list-nav-item;
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
        cursor: auto;
        & > ul {
            list-style-type: none;
            padding: 0;
            margin-left: 20px;
        }
        &--main {
            border: none;
        }
    }

    &-nav-link {
        @extend .blog-list-nav-link;
        line-height: 35px;
        &--main {
            font-weight: 500;
        }
    }

    &-content {
        flex-basis: 75%;
        margin-right: 50px;
        a {
            text-transform: uppercase;
            color: $font-color-black;
            font-weight: 500;
            text-decoration: underline;
            letter-spacing: 1px;
            font-size: $font-size-button;
            &:hover {
                color: $font-color-blue;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-basis: 70%;
            margin: 0 $spacer-xsmall 0 $spacer-small;
        }
    }
    &-heading {
        margin-bottom: 100px;
    }

    &-summary {
        display: flex;
        border-bottom: 1px solid $border-color-gray;
        padding: $spacer 0;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            padding-top: 15px;
        }
    }

    &-summary-info {
        flex-basis: 70%;
        padding: 0 $spacer;

        @include media-breakpoint-down(lg) {
            padding: $spacer-small;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 $spacer-small $spacer-small $spacer-small;
            padding: 0;
        }

        .summary__content {
            margin-top: $spacer-small+$spacer-xsmall;
        }
    }

    &-summary-title {
        margin-top: 0;
        margin-bottom: $spacer-xsmall;
        line-height: 40px;

        a {
            font-size: $font-size-title;
            font-weight: 300;
            color: $font-color-black;
        }
    }

    &-feature-image {
        flex-basis: 30%;
        background-size: cover;
        background-position: 50% 20%;
        height: 260px;
        padding: 130px 0;
        &.narrow {
            margin-bottom: $spacer;
        }
        @include media-breakpoint-down(lg) {
            margin: $spacer-small;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 $spacer-small $spacer-small $spacer-small;
        }

        @include media-breakpoint-down(xs) {
            padding: 100px 0;
        }

        img {
            display: none;
        }
    }
}

.summary-blog-post-meta {
    &-author {
        display: inline;
    }

    &-list-author-name {
        color: $font-color-black;
        font-size: $font-size-button;
        line-height: 15px;
        font-weight: 400;
        &:hover{
            color:$font-color-blue;
        }
    }

    &.teaser {
        display: inline;
    }
}

.summary-read-more {
    &.blog-list {
        margin-top: $spacer;
    }

    &-link {
        font-size: $font-size-button;
        text-transform: uppercase;
        color: $font-color-black;
        font-weight: 500;
        letter-spacing: 1px;
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        padding-bottom: 2px;
    }
}

.blog-post-category {
    display: block;
    color: $font-color-blue;
    font-size: $font-size-teaser-name;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0 0 $spacer-small 0;
}

.blog-post-date {
    font-size: $font-size-button;
    color: $font-color-black;
    font-weight: 400;
    padding-left: $spacer;
    &:hover{
        color: $font-color-black;
    }
}
.article-heading {
    margin-top: 0;
}