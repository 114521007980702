
/*@import url('//fonts.googleapis.com/css?family=Roboto:400,400i,700');*/
@import url("/fonts/fonts.googleapis.com/css?family=Open+Sans:200italic,300italic,400italic,500italic,600italic,700italic,800italic,900italic,200,300,400,500,600,700,800");
@import url('./fonts/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:100,200,300,400,500|Frank+Ruhl+Libre:300');
@font-face {
    font-family: 'Courier';
    src: url('blog-css/fonts/Courier/Courier-01.ttf');
    font-weight: 400;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}

body {
    margin: 0;  
    font-family: "Roboto",sans-serif;
    font-size: 18px;
    line-height: 1.7;
    color: #333333;
    overflow-x: hidden;
    font-weight: 300;

}

h1 {
    font-size: $font-size-main-title ;
    font-weight: 100;
    line-height: 88px;

}
h2 {
    font-size: $font-size-heading;
    font-weight: 100;
    line-height: 64px;
    color: $font-color-black;
}
h3 {
    font-size: 35px;
    font-weight: 300;
    line-height: 46px;
}

a {
    text-decoration: none;
    color: #592a88;
}
a:focus, a:active, 
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="range"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0 !important;
    outline : 0 none !important;
}
a:hover {
    color: $font-color-blue;
}
a.button:hover {
    color: $font-color-black;
}
*:focus {
    outline: 0 none;
    border: none;
}
img {
    max-width: 100%;
    height: auto;
}
.button {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    color: white;
    background-color: $font-color-blue;
    text-transform: uppercase;
    font-size: $font-size-button;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 15px 25px;
    margin: 0 15px;
    cursor: pointer;
    border: none;
    &:hover {
        color: $font-color-black;
    }
    &:focus {
        outline: 0 none;
        border: none;
    }
}

code {
    border: 1px solid $slider-gray;
    border-radius: 5px;
    padding: 2px;
    background-color: $background-color-light-gray;
    font-weight: 500;
    color: $font-color-black;
    font-family: 'Courier';
    font-size: $font-size-base;
}
/* */

.main_content::after {
    content: "";
    clear: both;
    display: table;
}


.blog-meta-author a { border-bottom: 0; }
.blog-meta-author a span { border-bottom: 1px dotted #ccc; }
.blog-meta-author span.img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    margin-right: 3px;
}
.blog-meta-author img {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    border-radius: 50%;
}

/* AUTHOR BOX */

.blog-author-box {
    /*border-top: 1px dotted #ddd;*/
}
.blog-author-box__content {
    margin: 1.5em 0 1em;
    display: flex;
}
.blog-author-box__avatar {
    flex: 0 0 auto;
    margin-right: 24px;
}
.blog-author-box__avatar img {
    border-radius: 50%;
    display: block;
}
.blog-author-box .blog-author-box__title {
    margin-bottom: 4px;
    font-size: 19px;
    margin-top: 3px;
}
.blog-author-box .blog-author-box__title a {
    color: inherit;
}
.blog-author-box .blog-author-box__bio {
    font-size: 14.5px;
    line-height: 22px;
    color: #555;
}
.blog-author-box .blog-author-box__bio :first-child {
    margin-top: 0;
}

/* PAGINATION */

ul.pagination {
    padding: 0;
    margin: 40px 0;
}

ul.pagination a {
    height: 38px;
    padding: 0 22px;
    line-height: 38px;
    display: inline-block;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border: 1px solid #ddd;
    color: #333;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    font-size: 16px;
    transition: all 0.1s ease-in;
}

ul.pagination a:hover {
    background-color: $font-color-blue;
    color: white !important;
}

ul.pagination .page-item {
    display: none;
}
ul.pagination .page-item span {
    display: none;
}
ul.pagination .page-item:nth-child(2) {
    display: inline-block;
    float: right;
}
ul.pagination .page-item:nth-child(2) a::before {
    content: "Newer Posts";
}
ul.pagination .page-item:nth-last-child(2) {
    display: inline-block;
}
ul.pagination .page-item.disabled {
    display: none;
}

ul.pagination .page-item:nth-last-child(2) a::before {
    content: "Older Posts";
}



/* bootstrap */

.container {
    margin-right: auto;
    margin-left: auto;
    padding-top:115px;
  &.academy {
      padding-top:0;
  }

}
.row {
    margin-left: -15px;
    margin-right: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: $spacer;
    padding-right: $spacer;
    @include media-breakpoint-down(sm) {
        padding-left: $spacer-small;
        padding-right: $spacer-small;
    }
    
}

.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after {
    content: " ";
    display: table;
}

.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after {
    content: " ";
    display: table;
}

.clearfix:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after {
    clear: both;
}

@media (min-width: 992px) {
    .col-md-4 {
        width: 33.33333333%;
    }
}
@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-12 {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .col-sm-12 {
        width: 100%;
    }
}

 /**
 * newsletter
 */
#mc_embed_signup {
  background: #592a88;
  padding: 30px 0;

}

#mc_embed_signup form {
  background: url('/blog-img/subscribe.png') no-repeat center bottom;
  padding-bottom: 78px !important;
}

@media screen and (max-width: 768px) {
  #mc_embed_signup form {
    background-size: 100% auto;
  }
}

#mc_embed_signup h1 {
  color: white;
  margin-bottom: 0.67em;
  font-weight: normal;
  font-size: 30px;
}

body #mc_embed_signup .button {
  background: #e16aa8 !important;
  font-weight: bold;
  margin-left: 5px;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

body #mc_embed_signup .button:hover {
  background: #d63288 !important;
}

body #mc_embed_signup .button,
body #mc_embed_signup input.email {
  padding: 15px;
  line-height: 1;
  height: 49px;
  border-radius: 25px !important;
}

body #mc_embed_signup .button {

  background: #26b7eb;
}

body #mc_embed_signup .button:hover {
  background: #1186b3;
}

/**
 * Share
 */

.blog_post_share {
    padding: 2em 0;
}

/**
 * Sidebar
 */

#vertabelo-sidebar.shown #vertabelo-sidebar-btn {
    right: 300px;
}

#vertabelo-sidebar.shown #vertabelo-sidebar-cont {
    right: 0;
}

#vertabelo-sidebar.shown #vertabelo-sidebar-btn div {
    transform: rotate(-180deg);
}

#vertabelo-sidebar-btn, #vertabelo-sidebar-cont {
    transition: 0.15s;
}
#vertabelo-sidebar-btn {
    font-size: 50px;
    width: 35px;
    height: 50px;
    background: linear-gradient(to right bottom, #a873d4, #592a87);
    color: #fff;
    border-radius: 100% 0% 0% 100%;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    border: 1px solid #ccc;
    border-right: none;
    position: fixed;
    top: 30vh;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
}

#vertabelo-sidebar-btn div {
    transition: 0.4s;
    height: 50px;
    line-height: 37px;
}

.sidebar__content {
    position: fixed;
    top: 30vh;
    right: -301px;
    width: 301px;
    background-color: #fff;
    border-radius: 0 0 0 5px;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 299;
    overflow-y: auto;
    max-height: 50vh;

    padding: 10px;
}

@media screen and (max-width: 768px) {
    #vertabelo-sidebar-cont {
        top: 0;
        height: 100vh;
        max-height: none;
    }
}

@media screen and (max-width: 768px) {
    #vertabelo-sidebar.shown #vertabelo-sidebar-cont {
        width: 100vw;
        right: 0;
        border-radius: 0;
        border: none;
        /* z-index: 301; */
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 768px) {
    #vertabelo-sidebar.shown #vertabelo-sidebar-btn {
        right: 0;
    }
}

@media screen and (max-width: 768px) {
    #vertabelo-sidebar-btn {
        opacity: 0.4;
    }
}
@media screen and (max-width: 980px) {
    #vertabelo-sidebar-btn {
        top: 50vh;
    }
}

.sidebar__section-title {}

.sidebar__item {
    border: 1px solid #ccc;
    border-radius: 3px;
    overflow: hidden;

    text-align: center;
    font-size: 14px;
    width: 80%;
    margin: 0 auto 1em;
}

.sidebar__item img {
    vertical-align: top;
}

.sidebar__item-title {
    border-top: 1px solid #ccc;
    padding: 0.5em;
    background-color: #fafafa;
}


/**
 * Related posts
 */

.related-posts__content {
    margin-bottom: 1em;
}




@media (min-width: 992px) {
    .col-md-4.related-posts__item:nth-child(3n+1) {
        clear: left;
    }
}





.blog_post_control_item {
    /*float: left;*/
    margin-right: 14px;
    font-size: 16px;
}

.blog_post_control_item a, .blog_post_control_item .share_item.share_sign {
    height: 38px;
    padding: 0 22px;
    line-height: 38px;
    display: inline-block;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border: 1px solid #f47e00;
    color: inherit;
    -o-transition: all  0.1s ease-in;
    -webkit-transition: all  0.1s ease-in;
    -moz-transition: all  0.1s ease-in;
    transition: all  0.1s ease-in;
}
.blog_post_control_item a:hover, .blog_post_control_item .share_item.share_sign:hover {
    background-color: #f47e00;
    color: #fff;
}

.blog_post_control_item .share_item {
    width: 38px;
    padding: 0;
    text-align: center;
    font-size: 14px;
    border: none;
    border: 1px solid #333;
}

.blog_post_control_item .social_share_item_wrapper {
    display: inline-block;
    visibility: hidden;
    filter: alpha(opacity=0);
    opacity: 0;
    -moz-transform: translate(20px);
    -webkit-transform: translate(20px);
    transform: translate(20px);
    -moz-transition: all 0.35s ease-in;
    -webkit-transition: all 0.35s ease-in;
    transition: all 0.35s ease-in;
}

.blog_post_control_item .social_share_item_wrapper.animating {
    visibility: visible;
    filter: alpha(opacity=100);
    opacity: 1;
    -moz-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0);
}

.blog_post_control_item .social_share_item_wrapper:nth-child(1) {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(2) {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(3) {
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(4) {
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(5) {
    -webkit-transition-delay: 250ms;
    transition-delay: 250ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(6) {
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(7) {
    -webkit-transition-delay: 350ms;
    transition-delay: 350ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(8) {
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
}

.blog_post_control_item .social_share_item_wrapper:nth-child(9) {
    -webkit-transition-delay: 450ms;
    transition-delay: 450ms;
}

.blog_post_control_item .share_item.share_sign {
    border-color: #ddd;
    padding: 0;
    cursor: pointer;
    visibility: visible;
    filter: alpha(opacity=100);
    opacity: 1;
}

.blog_post_control_item .share_item.share_sign:hover {
    background-color: transparent;
    color: inherit;
}

.blog_post_control_item .share_item.share_facebook {
    border-color: #234e9a;
}
.blog_post_control_item .share_item.share_facebook:hover {
    background-color: #234e9a;
}

.blog_post_control_item .share_item.share_twitter {
    border-color: #32aadc;
}
.blog_post_control_item .share_item.share_twitter:hover {
    background-color: #32aadc;
}

.blog_post_control_item .share_item.share_googleplus {
    border-color: #e2131e;
}
.blog_post_control_item .share_item.share_googleplus:hover {
    background-color: #e2131e;
}

.blog_post_control_item .share_item.share_linkedin {
    border-color: #007bb5;
}
.blog_post_control_item .share_item.share_linkedin:hover {
    background-color: #007bb5;
}

.blog_post_control_item .share_item.share_pinterest {
    border-color: #c8071d;
}
.blog_post_control_item .share_item.share_pinterest:hover {
    background-color: #c8071d;
}

.blog_post_control_item .share_item.share_vk {
    border-color: #507299;
}
.blog_post_control_item .share_item.share_vk:hover {
    background-color: #507299;
}

.blog_post_control_item .share_item.share_tumblr {
    border-color: #35465c;
}
.blog_post_control_item .share_item.share_tumblr:hover {
    background-color: #35465c;
}

.blog_post_control_item .share_item.share_reddit {
    border-color: #ff4500;
}
.blog_post_control_item .share_item.share_reddit:hover {
    background-color: #ff4500;
}

.blog_post_control_item a:hover, .blog_post_control_item .share_item.share_sign:hover {
    color: #fff !important; /* bo sam dałem important gdzie indziej */
}

/*.blog_single*/ .blog_post_control_item .social_share_item_wrapper {
    visibility: visible!important;
    filter: alpha(opacity=100)!important;
    opacity: 1!important;
    -moz-transform: translate(0)!important;
    -webkit-transform: translate(0)!important;
    transform: translate(0)!important;
}

/*.blog_single*/ .blog_post_control_item .share_item.share_sign {
    border: none;
    cursor: default;
    width: auto;
    margin-right: 12px;
}
// ads
.rek-banner-text {
    border-radius: 15px;
    padding: ($spacer-small + $spacer-xsmall) $spacer-content;
    display: flex;
    align-items: center;
    &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        height: 40px;
        width: 40px;
        flex: 1 0 40px;
    }
    p {
        margin: 0 0 0 17px;
        flex: 2 1 auto;
        line-height: 1.3em;
        a {
            font-weight: 500;
            text-decoration: underline;
        }
    }
}
.text-green {
    background-color: #f1f9f7;
    color: #3d8262;
    &::before {
        background-image: url("/blog-img/banner-green.svg");
    }
    a {
        color: #3d8262;
    }
}
.text-red {
    background-color: #fdf3ef;
    color: #ae3f1d;
    &::before {
        background-image: url("/blog-img/banner-red.svg");
    }
    a {
        color: #ae3f1d;
    }
}
.text-blue {
    background-color: #f0f6ff;
    color: #0f69d8;
    &::before {
        background-image: url("/blog-img/banner-blue.svg");
    }
    a {
        color: #0f69d8;
    }
}
