
pre {
    overflow: auto;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

/* CONTENT */

// .main_content ul li {
//     list-style: none;
//     position: relative;
// }

// .main_content ul li:before {
//     content: ' ';
//     display: inline-block;
//     width: 8px;
//     height: 8px;
//     background: #793ea7;
//     position: absolute;
//     left: -20px;
//     top: 14px;
// }

.main_content ol {
  counter-reset: li 0;
}

.main_content ol[start="2"] { counter-reset: li 1; }
.main_content ol[start="3"] { counter-reset: li 2; }
.main_content ol[start="4"] { counter-reset: li 3; }
.main_content ol[start="5"] { counter-reset: li 4; }
.main_content ol[start="6"] { counter-reset: li 5; }
.main_content ol[start="7"] { counter-reset: li 6; }
.main_content ol[start="8"] { counter-reset: li 7; }
.main_content ol[start="9"] { counter-reset: li 8; }

.main_content ol li {
  list-style: none;
  position: relative;
}

.main_content ol li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: 8px;
  left: -26px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 15px;
  height: 16px;
  line-height: 16px;
  padding: 0;
  color: #fff;
  font-size: 12px;
  background: #793ea7;
  text-align: center;
}

.main_content code {
    font-family: "Courier New", monospace;
    font-size: 90%;
    border-radius: 4px;
    background: #e8e8e8;
    border: 1px solid #cccccc;
    line-height: 1;
    color: #333333;
    padding: 2px;
    display: inline-block;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

/* --------
start typography elements
------------------------------------------- */
dd {
    border-left: 4px solid #f2f2f2;
    margin-left: 0;
    padding-left: 16px;
    margin-left: 15px;
}
/* --------
end typography elements
------------------------------------------- */

/* --------
start table
------------------------------------------- */
table {
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: transparent;
    border-collapse: collapse;
}

table a {
    color: inherit;
}

table a:hover {
    border-bottom: 1px dotted #333;
}

table tr {
    border-bottom: 1px solid $border-light-gray;
}



table th, table td {
    border-left: 1px solid $border-light-gray;
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: top;
}

table, table th, table td {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

table th:last-child, table td:last-child {
    border-right: 1px solid $border-light-gray;
}

.post_content table {
    border-bottom: 1px solid $border-light-gray;
}

.post_content table th, .post_content table td {
    border-top: 1px solid $border-light-gray;
}
/* --------
end table
------------------------------------------- */

/* --------
start blockquote
------------------------------------------- */
blockquote {
  position: relative;
  margin: 50px 0;
  font-weight: bold;
  border-top: 1px dashed #cacaca;
  display: block;
  border-bottom: 1px dashed #cacaca;
  padding: 8px 65px;
  text-align: center;
  line-height: 1.6em;
  /*font-size: 1.1em;*/
  font-size: 1.2em;
  border-left: none;
  border-right: none;
}

blockquote:before {
  content: "\201C";
  position: absolute;
  left: 0;
  top: 43px;
  font-size: 75px;
  color: #EDEDED;
  font-family: Georgia,"Times New Roman",Times,serif;
  font-style: initial;
}

blockquote {
    /*font-size: 1.2rem;*/
    color: #592a88;
}

.main_content.col-md-12 blockquote {
  padding: 8px 120px;
}

.main_content.col-md-12 blockquote:before {
  top: 50px;
  font-size: 100px;
}

blockquote cite {
    font-style: normal;
    clear: both;
    display: block;
    text-align: right;
    font-size: 12px;
    margin-top: 11px;
    margin-bottom: 0;
    line-height: 28px;
    color: #666;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

blockquote.pull-left, blockquote.pull-right {
    width: 260px;
    border-left: 0;
    border-right: 0;
}

blockquote.pull-right {
    padding: 10px 20px;
}

blockquote.pull-right:before {
    content: "\f10e";
    left: auto;
    right: 0;
}
/* --------
end blockquote
------------------------------------------- */
img.expand {
    width: 140%;
    @media screen and (min-width: 1000px) {
        max-width: 140%;
        margin-left: -20%;
    }
    box-shadow: 0px 3px 15px #ccc;
    border-radius: 5px;
}
/* --------
start alignment
------------------------------------------- */
.alignleft {
    float: left;
}

.alignright {
    float: right;
}

.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

blockquote.alignleft,
figure.wp-caption.alignleft,
img.alignleft {
    margin: 4px 20px 10px;
    margin-left: 0;
}

.wp-caption.alignleft {
    margin: 4px 14px 10px;
    margin-left: 0;
}

blockquote.alignright,
figure.wp-caption.alignright,
img.alignright {
    margin: 4px 20px 10px;
    margin-right: 0;
}

.wp-caption.alignright {
    margin: 4px 14px 10px;
    margin-right: 0;
}

blockquote.aligncenter,
img.aligncenter,
.wp-caption.aligncenter {
    margin-top: 0;
    margin-bottom: 0;
}

.entry-content blockquote.alignleft,
.entry-content blockquote.alignright {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 17px;
    width: 50%;
}

.entry-content blockquote.alignleft p,
.entry-content blockquote.alignright p {
    margin-bottom: 17px;
}

.wp-caption {
    margin-bottom: 24px;
}

.wp-caption img[class*="wp-image-"] {
    display: block;
    margin: 0;
}

.wp-caption {
    color: #767676;
}

.wp-caption-text {
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
    font-size: 12px;
    font-style: italic;
    line-height: 1.5;
    margin: 9px 0;
}

div.wp-caption .wp-caption-text {
    padding-right: 10px;
}

div.wp-caption.alignright img[class*="wp-image-"],
div.wp-caption.alignright .wp-caption-text {
    padding-left: 10px;
    padding-right: 0;
}

.wp-smiley {
    border: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}
/* --------
end alignment
------------------------------------------- */

.syntaxhighlighter {
  padding: 14px !important;
  border-left: 4px solid #00b5e9;
}

body .syntaxhighlighter,
body .syntaxhighlighter .line.alt2,
body .syntaxhighlighter .line.alt1 {
  background: #e8e8e8 !important;
}

body .syntaxhighlighter .plain, body .syntaxhighlighter .plain a {
  color: #666 !important;
}

body .syntaxhighlighter table td.code .line {
  padding: 5px 1em 0 !important;
}
