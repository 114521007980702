.site-header {
    z-index: 2;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid #e9e9e9;
    position: fixed;
    transition: 0.2s ease 0s;
    height: $header-height;
    &.active {
        position: fixed;
    }

    .site-logo {
        margin-top: 6px;

        @include media-breakpoint-down(md) {
            max-width: 140px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 123px;
        }
    }

    .site-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 34px 20px 33px 20px;
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
    }

    .main-menu {
        line-height: 18px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .main-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .main-menu a {
        padding: 0 20px;
        background: none !important;
        color: black;
        text-transform: uppercase;
        font-size: $font-size-base;
        font-weight: 500;
        letter-spacing: 1px;

        @include media-breakpoint-down(md) {
            font-size: $font-size-button;
            padding: 0 10px;
        }

        &:hover,
        &.active {
            color: $font-color-blue;
        }
    }

    .main-menu__item {
        border-left: none;
        display: inline-block;

        &.singup {
            a {
                border: 1px solid $font-color-blue;
                padding: 12px 20px;
                border-radius: 5px;
                color: $font-color-blue;
                @include media-breakpoint-down(md) {
                    padding: 8px 16px;
                }
            }
        }

        &.learnsql {
            border-right: 1px solid black;
            padding-right: 15px;
            @include media-breakpoint-down(md) {
                padding-right: 8px;
            }
        }

        &.login {
            margin-left: 15px;
            @include media-breakpoint-down(md) {
                margin-left: 8px;
            }
        }
    }

    &.home {
        position: initial;

        .main-menu a {
            color: $background-color;

            &:hover {
                color: $font-color-blue;
            }
        }

        .main-menu__item {
            &.singup {
                &:hover {
                    a {
                        border: 1px solid $font-color-blue;
                        color: $font-color-blue;
                    }
                }

                a {
                    border: 1px solid $background-color;
                    color: $background-color;
                }
            }

            &.learnsql {
                border-right: 1px solid $font-color-gray;
                padding-right: 15px;
            }
        }
        .siteHeaderHome-navigation-layer-menu-icon.pages {
            background-color: $background-color;

            &:after {
                background-color: $background-color;
            }
        }
    }
}

.siteHeaderHome-navigation-layer-menu.pages {
    display: none;

    @include media-breakpoint-down(sm) {
        display: inline-block;
    }
}

.siteHeaderHome-navigation-layer-menu-icon.pages {
    background-color: $font-color-blue;

    &:after {
        background-color: $font-color-blue;
    }
}
.site-header.sites {
    &.nav-up {
        top: -$header-height;
    }
}
