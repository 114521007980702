.imprint {
    text-align: center;
    padding-bottom: 150px;
    background: url(/blog-img/Visual_imprint.png) no-repeat center center;
    &__heading {
        font-size: $font-size-heading;
        color: $font-color-black;
        position: relative;
        margin-bottom: 60px;
        &::after {
            content: " ";
            width: 100px;
            height: 3px;
            background-color: $font-color-blue;
            display: block;
            position: absolute;
            margin: 0 auto;
            border-top: 2px solid transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
        }
    }
    &__text {
        color: $font-color-gray;
    }
}
.contact-box {
    max-width: 557px;
    max-height: 350px;
    background-color: $background-color;
    border: 1px solid $border-color-gray;
    border-radius: 5px;
    box-shadow: 0 22px 24px 0 rgba(0,0,0,0.05);
    text-align: left;
    padding:  30px 60px 60px;
    margin: 0 auto 0 50%;
    margin-top: 60px;
    @include media-breakpoint-down(md) {
        max-height: none;
        margin: 0 auto;
        text-align: center;
    }
    &__heading {
        font-size: 30px;
        color: $font-color-blue;
        font-weight: 400;
        margin: 0 0 $spacer-small;
        @include media-breakpoint-down(sm) {
            font-size: 5vw;
            line-height: $font-size-card-title;
        }
    }
    &__address-and-contact {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    &__left-column {
        margin-right: $spacer;
    }
    &__label {
        font-size: $font-size-base;
        color: $font-color-gray;
    }
    &__data {
        color: $font-color-black;
        font-weight: 400;
        margin: 0;
        line-height: 28px;
    }
    &__legal-information {
        font-size: 12px;
        font-weight: 400;
    }
    &__legal-data {
        color: $font-color-black;
        margin: 0;
        line-height: 19px;
    }
    &__legal-label {
        color: $font-color-gray;
    }
    &__website-link {
        color: $font-color-black;
        text-decoration: underline;
    }
}
.to-top {
    display: none;
}
