.section-home-base {
    margin: 0 auto;
    padding: 120px 0 100px;
    border: 1px solid #f2f2f2;
    box-shadow: 0 22px 34px 0 rgba(0, 0, 0, 0.04);

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    &.academy {
        padding-bottom: 0;
        margin-bottom: -100px;
        border-bottom: none;
        box-shadow: none;

        ul {
            list-style-type: none;
        }

        li::before {
            content: ".";
            margin-right: 1rem;
            position: relative;
            top: -0.25em;
            font-weight: 600;
            color: $font-color-gray;
        }
    }

    &-background-image {
        height: 1000px;
        background: url(/blog-img/home/Visual_Academy_home.png) 68% 50%
            no-repeat;

        @include media-breakpoint-down(md) {
            background: url(/blog-img/home/Visual_Academy_home.png) 82% 85%
                no-repeat;
            background-size: 180%;
        }

        @include media-breakpoint-down(sm) {
            background: url(/blog-img/home/Visual_Academy_home.png) 80% 75%
                no-repeat;
            background-size: 170%;
            margin-bottom: -90px;
        }

        @include media-breakpoint-down(xs) {
            background: url(/blog-img/home/Visual_Academy_home.png) 80% 80%
                no-repeat;
            background-size: 170%;
        }
    }

    &-interlude {
        border: 1px solid #f2f2f2;
        background-color: $background-color-light-gray;
        box-shadow: 0 22px 34px 0 $background-color;
    }

    &-subtitle {
        color: $font-color-blue;
        text-align: center;
        font-size: $font-size-content;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 16px;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }
    }

    &-title {
        text-align: center;
        position: relative;
        margin: 20px 0;

        &:after {
            content: "";
            width: 100px;
            height: 3px;
            background-color: $font-color-blue;
            display: block;
            position: absolute;
            margin: 0 auto;
            border-top: 2px solid transparent;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
        }

        @include media-breakpoint-down(sm) {
            font-size: $font-size-title;
            font-weight: 300;
            line-height: 45px;
            margin: 15px;
        }
    }

    &-info-interlude {
        text-align: center;
        color: $font-color-black;
        font-size: $font-size-footer-header;
        line-height: 30px;
        text-align: center;
        font-weight: 400;

        @include media-breakpoint-down(md) {
            font-size: $font-size-list-title;
        }
    }

    &-content {
        max-width: $container-width;
        margin: 80px auto 0 auto;

        @include media-breakpoint-down(md) {
            position: relative;
            margin: 70px auto 0 auto;
        }

        > ul {
            display: flex;
            list-style: none;
            padding-left: 0;
            justify-content: center;
            flex-wrap: wrap;

            > li {
                box-sizing: border-box;
                height: 160px;
                width: 160px;
                border: 1px solid #ebebeb;
                background-color: #ffffff;
                border-radius: 50%;
                margin: $spacer $spacer-small;
                position: relative;

                @include media-breakpoint-down(md) {
                    height: 131px;
                    width: 131px;
                    margin: $spacer-xsmall 8px;
                }
            }
        }

        &-academy {
            width: $container-width;
            margin: 140px auto 0 auto;
            @include media-breakpoint-down(md) {
                margin: 70px auto 0 auto;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    &-content-interlude {
        margin: 50px auto 0 auto;

        ul {
            li {
                width: 110px;
                height: 110px;
                box-shadow: 0 22px 24px 0 rgba(0, 0, 0, 0.02);
                margin: 0 $spacer-small;

                @include media-breakpoint-down(md) {
                    margin: $spacer-xsmall;
                }
            }
        }
    }

    &-content-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        img {
            max-width: 100px;
            max-height: 100px;
        }
    }

    &-content-circle-interlude {
        img {
            max-width: 75px;
            max-height: 75px;
        }
    }

    &-content-list {
        flex-basis: 50%;

        > ul {
            padding: 0;
        }

        li {
            display: flex;
            margin-bottom: $spacer;

            &:before {
                display: none;
            }
        }

        &-academy {
            flex-basis: 50%;
        }
    }

    &-content-list-container {
        display: flex;
        justify-content: flex-start;
    }

    &-content-list-image {
        align-self: baseline;
        margin: 25px 0 0 0;
        max-width: 70px;
        max-height: 80px;

        @include media-breakpoint-down(md) {
            margin: $spacer-small 0 0 0;
            max-width: 60px;
            max-height: 65px;
        }
    }

    &-content-list-info {
        margin-left: $spacer;

        @include media-breakpoint-down(md) {
            margin-left: $spacer-small;
        }
    }

    &-content-list-items {
        padding-left: 10px;
    }

    &-content-visual {
        flex-basis: 50%;
        img {
            max-width: 100vw;
            width: 55vw;
            margin-right: -25vw;
            @include media-breakpoint-down(md) {
                margin-right: 0;
                width: 100%;
            }
        }
    }

    &-content-title,
    &-content-title-academy {
        font-size: $font-size-teaser-title;
        line-height: 28px;
        font-weight: 400;
        margin: 10px 0 5px 0;
    }

    &-content-meat {
        font-size: 16px;
    }

    &-content-with-list {
        width: 40%;
        padding-left: $spacer;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0 auto;
            padding-left: $spacer;
            padding-right: $spacer;
        }
        @include media-breakpoint-down(xs) {
            padding-left: $spacer-small;
            padding-right: $spacer-small;
        }
    }
}

.scroll-down {
    background-color: transparent;
    border: none;
    padding: 0;
    position: absolute;
    top: 90%;
    z-index: 5;
    width: 25px;
    height: 40px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.mouse {
    width: 25px;
    height: 40px;
    border: 3px solid $border-color;
    border-radius: 25px;
    position: relative;
    @include media-breakpoint-down(sm) {
        display: none;
    }
    &:hover,
    &:focus {
        transition: 0.2s;
        border-color: $background-color;
    }
    &:hover &__dot,
    &:focus &__dot {
        animation-play-state: paused;
    }
    &__dot {
        background-color: $font-color-blue;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 15%;
        transform: translate(-50%, 0);
        animation: 3s ease infinite moveTheDot;
    }
}

.to-top.sticky_scroll {
    position: sticky;
    bottom: 25px;
    left: 95%;
    width: 60px;
    height: 60px;
    display: block;
    margin-bottom: 25px;
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.content-list-button.academy {
    margin: 20px 0 0;
}

@keyframes moveTheDot {
    0% {
        transform: translate(-50%, 250%);
    }
    50% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(-50%, 250%);
    }
}
