.slider.slick-slider {
    width: 70%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 12px 89px 0 rgba(0,0,0,0.07);
    border-radius: 55px;
    border-bottom-left-radius: 0px;
    position: relative;
    margin-bottom: 120px;
    &:before {
        content: "";
        border-top: 0px solid transparent;
        border-bottom: 68px solid transparent;
        border-left: 68px solid #fff;
        position: absolute;
        left: 0px;
        bottom: -55px;
        z-index: 2;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
        border-bottom-left-radius: 55px;
        width: 85%;
        &:before {
            content: "";
            position: absolute;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-top: 50px solid #fff;
            left: 50%;
            bottom: -115px;
            margin: 35px auto 0;
            transform: translate(-50%,0);
        }
    }
    .slider_item {
        @include media-breakpoint-down(md) {
            margin:0;
        }
    }
    .textCloud {
        /*max-width: 700px;*/
        background-color: $background-color;
        display: block;
        margin: 0 auto;
        @include media-breakpoint-down(md) {
            padding: $spacer-small;
        }
        &-content {
            color: #000000;
            font-size: $font-size-teaser-title;
            font-weight: 300;
            line-height: 37px;
            text-align: center;
            padding: $spacer $spacer-small 75px $spacer-small;
            font-family: "Frank Ruhl Libre";
            @include media-breakpoint-down(lg) {
                padding: 25px $spacer-small 45px $spacer-small;
            }
            @include media-breakpoint-down(md) {
                font-size: $font-size-footer-header;
                padding:  $spacer-small $spacer-small 25px $spacer-small;
            }
        }
        .slideCount {
            color: $font-color-gray;
            font-size: $font-size-button;
            font-weight: 300;
            letter-spacing: 1px;
            line-height: 18px;
            text-align: center;
            padding: 0 $spacer $spacer $spacer;
            .slideCountItem {
                color: $font-color-black;
                font-weight: 400;
            }
        }
    }
}
.slider-nav-thumbnails {
    @include media-breakpoint-down(md) {
        bottom: 0;
        left: 0;
        right: 0;
    }
    .slick-list.draggable {
        padding: 0 40px !important;
        margin-left: 150px;
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }
    .slick-slide {
        display: flex;
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            justify-content: center;
        }

    }
    &-info {
        max-width: 170px;
        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
          align-self: center;
        }
    }
    &-photo {
        align-self: center;
        margin-right: 25px;
        margin-bottom: $spacer-xsmall;
        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
    }
    &-social {
        display: flex;
        align-self: stretch;
        margin-top: 10px;
        justify-content: left;
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
    &-follow {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        position: relative;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-name {
        font-size: $font-size-list-title;
        line-height: 21px;
        font-weight: 400;
        padding-bottom: 5px;
        @include media-breakpoint-down(md) {
           text-align: center;
          }
    }
    &-position {
        font-size: $font-size-content;
        font-weight: 300;
        line-height: 20px;
    }
}
.slider-nav-thumbnails .slick-slide  {
    @include media-breakpoint-down(md) {
        opacity:0
    }
}
.slick-slide.slick-active{
    opacity:1;
    transition: opacity ease 1s;
}
.slick-slide.slick-active ~ .slick-slide.slick-active {
    opacity: 0.5;
}
.slick-slide:not(.slick-active)  {
    @include media-breakpoint-down(lg) {
        opacity:0
    }
}


$spacer-button-carusel: -15%;

button.slick-prev,
button.slick-next {
    position: absolute;
    top: 50%;
    display: block;
    transform: translate(0, -50%);
    cursor: pointer;
    background: transparent;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid $border-color;
    font-size: 0;
    outline: none;
    @include media-breakpoint-down(md) {
        top:50%;
    }
    &:before {
        content:url('/blog-img/home/arrow-grey-prev.png');
        display: inline-block;
    }
    &:hover:before {
        content:url('/blog-img/home/arrow-blue-next.png');
        transform: rotate(180deg)
    }
}
button.slick-next{
    right: $spacer-button-carusel;
    @include media-breakpoint-down(md) {
        right: 10%;
     }
     @include media-breakpoint-down(xs) {
        right: 5%;
     }
    &:before {
        transform: rotate(180deg)
    }
    &:hover:before {
        transform: rotate(0deg)
    }
}
button.slick-prev {
    left: $spacer-button-carusel;
    @include media-breakpoint-down(md) {
       left: 10%;
    }
    @include media-breakpoint-down(xs) {
        left: 5%;
     }
}
.slider.slick-slider {
    button.slick-prev, button.slick-next {
    @include media-breakpoint-down(md) {
        display: none !important;
     }
}
}