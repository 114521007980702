.section-intro-career {
    margin: 0 auto;

    &-content {
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 $spacer-small;
        padding-bottom: $spacer-content;

        a {
            font-weight: bold;
        }
    }

    & .acc-card {
        border: 1px solid $border-color-gray;
        border-radius: 5px;


        & .acc-title, & .acc-contents {
            padding: 10px;
        }

        & .acc-title {
            background-color: $background-color-light-gray;
            font-weight: bold;
            border-bottom: 1px solid $border-color-gray;
        }

        & .acc-contents {
            & :first-child {
                margin-top: 0;
            }

            & :last-child {
                margin-bottom: 0;
            }
        }
    }
}